import { IEnvironment, defaultEnvironment } from '@geovelo-frontends/commons';

let _environment: IEnvironment & { prevEventId: number; eventId: number; mdvEventId: number } = {
  ...defaultEnvironment,
  source: process.env.REACT_APP_CONFIG === 'mdv' ? 'MOIS_DU_VELO' : 'MAI_A_VELO',
  apiKey:
    process.env.REACT_APP_CONFIG === 'mdv'
      ? '58df85c7-845d-41cc-a248-f444d27fa560'
      : 'd238b39e-3d11-4762-b769-e2f22eb0ba2a',
  prevEventId: 5,
  eventId: 6,
  mdvEventId: 7,
};

if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development') {
  _environment = {
    ..._environment,
  };
}

if (process.env.REACT_APP_ENV === 'staging') {
  _environment = {
    ..._environment,
    prevEventId: 38,
    eventId: 39,
    mdvEventId: 40,
  };
}

if (process.env.REACT_APP_ENV === 'production') {
  _environment = {
    ..._environment,
    prevEventId: 38,
    eventId: 39,
    mdvEventId: 40,
  };
}

export const environment = _environment;
