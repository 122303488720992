import { Box, ButtonBase, Skeleton, Typography } from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../app/context';
import leaderboardImg from '../assets/img/leaderboards.png';
import registerImg from '../assets/img/register.png';
import Helmet from '../components/helmet';
import PageLayout from '../layouts/page';

function Home(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);
  const links = [
    {
      key: '/inscription',
      title: "S'inscrire",
      description: 'Inscrivez votre entité et appréciez les performances de votre communauté.',
      backgroundColor: '#95c120',
      image: registerImg,
      color: '#fff',
    },
    {
      key: '/classements',
      title: 'Résultats 2024',
      description:
        "Consultez les classements locaux et nationaux établis selon le type d'entité, leur taille, et la zone géographique d'activité.",
      backgroundColor: '#f7c3c1',
      image: leaderboardImg,
      color: '#e4332a',
    },
  ];

  return (
    <>
      <Helmet
        description={`${
          config === 'mdv'
            ? "Pour célébrer le début de la belle saison, Vélo Québec et Geovelo s'associent pour proposer un Défi dans le cadre du Mois du vélo ! Associations, employeurs, écoles ou territoires, participez pour faire réaliser le plus grand nombre de kilomètres possibles à votre communauté."
            : "Mai à vélo est l'occasion pour les associations, collectivités, établissements scolaires, entreprises et autres établissements français, de se défier au cours d'un challenge d'activité à vélo, local, national et unique en son genre !"
        }`}
        title={`${
          config === 'mdv' ? 'Le Mois du Vélo par Vélo Québec' : 'Challenges Mai à vélo par Geovelo'
        }`}
      />
      <PageLayout
        title={`${
          config === 'mdv' ? 'Le Mois du Vélo par Vélo Québec' : 'Challenges Mai à vélo par Geovelo'
        }`}
      >
        <Box display="flex" flexDirection="column" gap={10} width="100%">
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            marginX="auto"
            maxWidth="100%"
            width="80ch"
          >
            <Typography align="center" component={Box}>
              <p>
                {config === 'mdv'
                  ? "Pour célébrer le début de la belle saison, Vélo Québec et Geovelo s'associent pour proposer un Défi dans le cadre du Mois du vélo ! Associations, employeurs, écoles ou territoires, participez pour faire réaliser le plus grand nombre de kilomètres possibles à votre communauté."
                  : "Mai à vélo est l'occasion pour les associations, collectivités, établissements scolaires, entreprises et autres établissements français, de se défier au cours d'un challenge d'activité à vélo, local, national et unique en son genre !"}
              </p>
              <p>
                {config === 'mdv'
                  ? 'Une fois votre inscription réalisée, vous recevrez toutes les informations nécessaires pour faire de votre participation un succès.'
                  : "L'objectif : faire parcourir à sa communauté le plus de kilomètres à vélo au cours du mois de mai. Tous les kilomètres enregistrés avec l'application Geovelo sont pris en compte, et des classements locaux et nationaux sont établis selon le type d'entité, leur taille, et la zone géographique d'activité."}
              </p>
            </Typography>
          </Box>
          <Box
            alignItems="center"
            columnGap={10}
            display="flex"
            flexWrap="wrap"
            justifyContent="space-evenly"
            rowGap={5}
            width="100%"
          >
            {links ? (
              <>
                {links.map(({ key, title, description, backgroundColor, image, color }) => (
                  <Box key={key} maxWidth="100%" width={500}>
                    <ButtonBase
                      component={Link}
                      sx={{
                        alignItems: 'center',
                        backgroundColor,
                        backgroundImage: `url(${image})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        justifyContent: 'flex-end',
                        height: 250,
                        padding: 3,
                        width: '100%',
                      }}
                      to={key}
                    >
                      <Typography align="center" color={color} fontWeight={600} variant="h5">
                        {title}
                      </Typography>
                      <Typography align="center" color={color} variant="body2">
                        {description}
                      </Typography>
                    </ButtonBase>
                  </Box>
                ))}
              </>
            ) : (
              <Skeleton height={250} variant="rectangular" width={500} />
            )}
          </Box>
        </Box>
      </PageLayout>
    </>
  );
}

export default Home;
