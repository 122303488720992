import { CyclabilityZone, Event, Partner, User, UserGeogroup } from '@geovelo-frontends/commons';
import { createContext } from 'react';

interface IAppEventContext {
  config: string | undefined;
  current: Event | undefined;
}

interface IAppUserContext {
  current: User | null | undefined;
  geogroups: UserGeogroup[] | undefined;
  partners: Partner[] | undefined;
  registeredGroups: number[] | undefined;
}

interface IAppZonesContext {
  map: { [id: number]: { id: number; name: string } } | undefined;
  france: CyclabilityZone | undefined;
  regions: CyclabilityZone[] | undefined;
  epcisMap: { [regionId: number]: CyclabilityZone[] } | undefined;
  codesMap: { [id: number]: string } | undefined;
}

interface IAppActionsContext {
  getZones: () => void;
  setCurrentUser: (user: User | null | undefined) => void;
  setGeogroups: (userGeogroup: UserGeogroup[] | undefined) => void;
  setPartners: (partners: Partner[] | undefined) => void;
  setRegisteredGroups: (groups: number[] | undefined) => void;
  setConfig: (config: string | undefined) => void;
}

interface IAppContext {
  event: IAppEventContext;
  user: IAppUserContext;
  zones: IAppZonesContext;
  actions: IAppActionsContext;
}

export const AppContext = createContext<IAppContext>({
  event: { current: undefined, config: undefined },
  user: {
    current: undefined,
    geogroups: undefined,
    partners: undefined,
    registeredGroups: undefined,
  },
  zones: {
    map: undefined,
    france: undefined,
    regions: undefined,
    epcisMap: undefined,
    codesMap: {},
  },
  actions: {
    getZones: () => undefined,
    setCurrentUser: () => undefined,
    setGeogroups: () => undefined,
    setRegisteredGroups: () => undefined,
    setPartners: () => undefined,
    setConfig: () => undefined,
  },
});
