"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Event = void 0;
class Event {
  constructor(id, title, startDate, endDate, challengeTemplate) {
    this.id = id;
    this.title = title;
    this.startDate = startDate;
    this.endDate = endDate;
    this.challengeTemplate = challengeTemplate;
  }
}
exports.Event = Event;