import { SvgIcon, SvgIconProps } from '@mui/material';

export function MDVIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fill="none" height="816" viewBox="264 244 570 302" width="1056" {...props}>
      <path
        d="M 0,0 C 0,19.044 15.438,34.483 34.483,34.483 53.527,34.483 68.966,19.044 68.966,0 68.966,-19.044 53.527,-34.482 34.483,-34.482 15.438,-34.482 0,-19.044 0,0"
        fill="#f3c353"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,689.88147,398.29173)"
      />
      <path
        d="m 0,0 -27.384,71.104 c -0.235,0.705 2.116,2.586 4.701,2.703 2.469,0.235 4.584,-0.94 5.641,-4.112 L 5.877,6.346 29.03,69.695 c 1.057,3.407 3.291,4.347 5.407,4.112 2.467,-0.117 4.818,-1.998 4.583,-2.703 L 11.519,0 C 10.695,-2.116 9.403,-3.291 5.76,-3.291 2.116,-3.291 0.94,-2.116 0,0"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,316.36387,447.46093)"
      />
      <path
        d="m 0,0 v 65.815 c 0,3.057 1.881,5.173 4.818,5.173 h 43.721 c 2.704,0 4.114,-1.293 4.114,-3.645 v -0.586 c 0,-2.469 -1.41,-3.644 -4.114,-3.644 H 8.814 V 37.726 h 30.44 c 2.821,0 4.232,-1.291 4.232,-3.526 v -0.47 c 0,-2.468 -1.411,-3.643 -4.232,-3.643 H 8.814 V 2.821 h 40.665 c 2.703,0 4.114,-1.176 4.114,-3.525 v -0.589 c 0,-2.468 -1.411,-3.761 -4.114,-3.761 H 4.818 C 1.881,-5.054 0,-3.056 0,0"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,382.03053,444.17053)"
      />
      <path
        d="m 0,0 v 67.343 c 0,2.822 1.292,3.996 3.761,3.996 h 1.175 c 2.586,0 3.878,-1.174 3.878,-3.996 V 3.057 h 34.082 c 2.704,0 4.115,-1.293 4.115,-3.761 v -0.471 c 0,-2.469 -1.411,-3.879 -4.115,-3.879 H 4.818 C 1.88,-5.054 0,-3.056 0,0"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,470.10547,444.17053)"
      />
      <path
        d="m 0,0 -7.549,22.828 h 3.235 L -0.733,11.573 C 0.258,8.495 1.111,5.716 1.732,3.061 h 0.081 c 0.645,2.634 1.59,5.506 2.625,8.476 L 8.335,22.828 H 11.52 L 3.284,0 Z"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,412.2572,519.12893)"
      />
      <path
        d="M 0,0 -4.176,-4.85 H -6.301 L -3.3,0 Z m -0.747,-13.425 c 0.03,1.876 -0.777,4.821 -4.15,4.821 -3.07,0 -4.377,-2.748 -4.617,-4.821 z m -8.804,-2.141 c 0.059,-4.028 2.64,-5.685 5.661,-5.685 2.152,0 3.469,0.381 4.58,0.838 l 0.527,-2.115 c -1.052,-0.476 -2.89,-1.044 -5.517,-1.044 -5.083,0 -8.139,3.351 -8.139,8.265 0,4.962 2.952,8.84 7.782,8.84 5.397,0 6.808,-4.689 6.808,-7.705 0,-0.623 -0.037,-1.072 -0.103,-1.424 z"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,443.86,488.13147)"
      />
      <path
        d="m 338.733,246.695 h 2.993 v -24.042 h -2.993 z"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,0,816)"
      />
      <path
        d="m 0,0 c 2.887,0 5.043,2.67 5.043,6.387 0,2.769 -1.409,6.27 -4.987,6.27 -3.535,0 -5.072,-3.256 -5.072,-6.359 C -5.016,2.703 -2.938,0 -0.034,0 Z m -0.089,-2.23 c -4.489,0 -7.957,3.238 -7.957,8.434 0,5.481 3.641,8.687 8.22,8.687 4.758,0 7.98,-3.417 7.98,-8.403 0,-6.066 -4.265,-8.718 -8.21,-8.718 z"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,470.42773,516.6472)"
      />
      <path
        d="m 0,0 c 4.708,0 7.388,4.292 7.388,9.488 0,4.563 -2.396,9.284 -7.279,9.284 -5.022,0 -7.482,-4.619 -7.481,-9.543 C -7.375,4.378 -4.691,0 -0.035,0 Z m 9.841,-5.364 c -3.108,0.788 -6.146,1.723 -8.819,2.635 -0.489,0.168 -0.939,0.316 -1.402,0.332 -5.483,0.201 -10.144,4.195 -10.144,11.551 0,7.311 4.502,12.013 10.723,12.013 6.257,0 10.337,-4.791 10.337,-11.537 0,-5.867 -2.743,-9.609 -6.567,-10.953 v -0.133 c 2.281,-0.565 4.788,-1.115 6.749,-1.46 z"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,504.11067,516.42453)"
      />
      <path
        d="m 0,0 c 0,-1.716 0.058,-3.187 0.137,-4.485 h -2.658 l -0.161,2.681 h -0.081 c -0.763,-1.319 -2.513,-3.05 -5.476,-3.05 -2.591,0 -5.698,1.435 -5.698,7.174 v 9.573 h 2.995 V 2.844 c 0,-3.117 0.978,-5.242 3.696,-5.242 2.013,0 3.421,1.397 3.978,2.735 0.155,0.428 0.274,0.96 0.274,1.526 v 10.03 H 0 Z"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,541.5052,513.1484)"
      />
      <path
        d="M 0,0 -4.177,-4.85 H -6.3 L -3.299,0 Z m -0.747,-13.425 c 0.028,1.876 -0.775,4.821 -4.149,4.821 -3.072,0 -4.379,-2.748 -4.618,-4.821 z m -8.805,-2.141 c 0.06,-4.028 2.641,-5.685 5.66,-5.685 2.154,0 3.469,0.381 4.582,0.838 l 0.53,-2.115 c -1.057,-0.476 -2.894,-1.044 -5.522,-1.044 -5.084,0 -8.136,3.351 -8.136,8.265 0,4.962 2.952,8.84 7.784,8.84 5.392,0 6.802,-4.689 6.802,-7.705 0,-0.623 -0.034,-1.072 -0.098,-1.424 z"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,563.0612,488.13147)"
      />
      <path
        d="m 0,0 c 0,-0.368 0.041,-0.743 0.123,-1.082 0.564,-2.066 2.341,-3.485 4.511,-3.485 3.163,0 5.023,2.534 5.023,6.295 C 9.657,5.02 7.938,7.84 4.727,7.84 2.685,7.84 0.776,6.427 0.175,4.179 0.079,3.813 0,3.412 0,2.94 Z M -2.99,17.473 H 0 V 7.183 h 0.068 c 1.056,1.84 2.971,3 5.64,3 4.11,0 6.992,-3.381 6.982,-8.341 0,-5.875 -3.76,-8.78 -7.447,-8.78 -2.387,0 -4.298,0.926 -5.544,3.063 h -0.082 l -0.151,-2.694 h -2.594 c 0.079,1.128 0.138,2.771 0.138,4.227 z"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,574.82427,510.36973)"
      />
      <path
        d="M 0,0 C 0.026,1.876 -0.777,4.82 -4.153,4.82 -7.221,4.82 -8.527,2.073 -8.767,0 Z m -8.804,-2.142 c 0.06,-4.027 2.64,-5.684 5.661,-5.684 2.148,0 3.466,0.381 4.577,0.838 l 0.529,-2.116 c -1.051,-0.475 -2.89,-1.043 -5.517,-1.043 -5.085,0 -8.139,3.351 -8.139,8.265 0,4.962 2.953,8.84 7.783,8.84 5.396,0 6.806,-4.689 6.806,-7.705 0,-0.623 -0.038,-1.072 -0.102,-1.424 z"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,610.70053,506.0312)"
      />
      <path
        d="m 0,0 c -0.788,-0.384 -2.517,-0.95 -4.746,-0.95 -4.989,0 -8.255,3.359 -8.255,8.369 0,5.049 3.478,8.731 8.891,8.731 1.772,0 3.37,-0.441 4.18,-0.872 l -0.668,-2.287 c -0.74,0.39 -1.861,0.787 -3.517,0.787 -3.773,0 -5.847,-2.8 -5.837,-6.202 0,-3.795 2.466,-6.127 5.762,-6.127 1.682,0 2.831,0.423 3.682,0.787 z"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,635.20187,518.33467)"
      />
      <path
        d="m 0,0 h 37 c 1.474,0 2.67,-1.193 2.67,-2.668 v -22.145 c 0,-1.474 -1.196,-2.668 -2.67,-2.668 H 0 c -1.474,0 -2.667,1.194 -2.667,2.668 V -2.668 C -2.667,-1.193 -1.474,0 0,0"
        fill="#56af31"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,337.5976,485.72787)"
      />
      <path
        d="M 0,0 C 0,0.843 0.685,1.526 1.527,1.526 2.37,1.526 3.054,0.843 3.054,0 3.054,-0.842 2.37,-1.526 1.527,-1.526 0.685,-1.526 0,-0.842 0,0"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,370.60413,503.6992)"
      />
      <path
        d="m 0,0 0.031,0.013 c -5.598,0.66 -10.02,5.36 -10.02,11.178 0,6.251 5.151,11.316 11.373,11.316 6.221,0 11.373,-5.065 11.373,-11.316 0,-3.879 -1.965,-7.261 -4.935,-9.281 l 7.305,-2.271 v -1.444 z m 1.384,20.249 c -5.006,0 -8.942,-4.081 -8.942,-9.029 0,-4.92 3.994,-9.03 8.942,-9.03 4.948,0 8.942,4.11 8.942,9.03 0,4.948 -3.936,9.029 -8.942,9.029"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,370.79493,518.6588)"
      />
      <path
        d="m 0,0 6.155,-13.16 9.023,19.31 h 3.099 L 7.261,-16.911 H 5.04 L -2.702,-0.789 -6.538,-1.907 -6.747,2.091 4.102,2.659 4.177,1.218 Z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,339.49213,493.17573)"
      />
      <path
        d="m 0,0 h 37 c 1.474,0 2.67,-1.193 2.67,-2.668 v -22.145 c 0,-1.474 -1.196,-2.668 -2.67,-2.668 H 0 c -1.474,0 -2.667,1.194 -2.667,2.668 V -2.668 C -2.667,-1.193 -1.474,0 0,0 Z"
        fill="none"
        stroke="#ffffff"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="0.654"
        transform="matrix(1.3333333,0,0,-1.3333333,337.5976,485.72787)"
      />
      <path
        d="m 0,0 c 0.367,-0.099 0.829,-0.136 1.389,-0.136 2.024,0 3.257,0.983 3.257,2.776 0,1.715 -1.214,2.546 -3.065,2.546 C 0.849,5.186 0.29,5.128 0,5.05 Z m -1.677,6.266 c 0.81,0.133 1.87,0.25 3.219,0.25 1.659,0 2.873,-0.386 3.645,-1.08 C 5.9,4.818 6.324,3.874 6.324,2.718 6.324,1.541 5.977,0.616 5.322,-0.06 4.435,-1.005 2.988,-1.486 1.35,-1.486 c -0.501,0 -0.964,0.02 -1.35,0.117 v -5.206 h -1.677 z"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,281.17187,510.1432)"
      />
      <path
        d="m 0,0 -1.272,3.74 c -0.29,0.848 -0.482,1.62 -0.675,2.372 H -1.986 C -2.179,5.34 -2.391,4.55 -2.641,3.759 L -3.914,0 Z m -4.261,-1.312 -1.35,-4.086 h -1.735 l 4.415,12.994 h 2.025 L 3.529,-5.398 H 1.735 l -1.388,4.086 z"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,298.93093,511.71227)"
      />
      <path
        d="m 0,0 h 1.716 c 1.793,0 2.93,0.983 2.93,2.468 0,1.678 -1.214,2.41 -2.988,2.43 C 0.848,4.898 0.27,4.82 0,4.742 Z m -1.677,5.919 c 0.848,0.174 2.063,0.27 3.219,0.27 1.793,0 2.951,-0.328 3.76,-1.059 0.656,-0.58 1.022,-1.466 1.022,-2.468 0,-1.718 -1.08,-2.855 -2.449,-3.316 v -0.06 c 1.003,-0.347 1.601,-1.27 1.909,-2.621 0.424,-1.812 0.733,-3.064 1.002,-3.566 H 5.051 C 4.839,-6.535 4.55,-5.418 4.184,-3.799 3.798,-2.005 3.104,-1.331 1.581,-1.271 H 0 v -5.63 h -1.677 z"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,308.4108,509.7084)"
      />
      <path
        d="M 0,0 C 0.458,1.083 17.763,12.149 24.152,9.451 26.101,8.628 27.023,6.581 26.2,4.631 23.593,-1.542 1.875,-1.302 0.684,-0.799 -0.075,-0.479 -0.137,-0.325 0,0"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        id="path45"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,404.7916,340.35547)"
      />
      <path
        d="m 0,0 c 0,1.858 1.221,3.026 3.132,3.026 h 1.433 c 2.123,0 2.707,-0.797 3.557,-2.76 L 16.245,-18.05 24.42,0.266 c 0.849,2.017 1.486,2.76 3.503,2.76 h 1.327 c 1.965,0 3.238,-1.168 3.238,-3.026 v -29.25 c 0,-1.646 -0.689,-2.496 -2.282,-2.496 h -0.743 c -1.54,0 -2.176,0.85 -2.176,2.496 v 25.215 l -7.592,-17.093 c -0.584,-1.381 -1.168,-2.336 -3.504,-2.336 -2.389,0 -2.866,0.955 -3.503,2.336 L 5.203,-4.194 V -29.25 c 0,-1.646 -0.638,-2.496 -2.23,-2.496 H 2.124 C 0.637,-31.746 0,-30.896 0,-29.25 Z"
        fill="#f3c353"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,279.53453,269.73827)"
      />
      <path
        d="m 0,0 c 0,7.645 -4.937,13.112 -11.891,13.112 -6.955,0 -11.839,-5.308 -11.839,-13.006 0,-7.645 4.938,-13.06 11.891,-13.06 C -4.884,-12.954 0,-7.644 0,0 m -29.25,0 c 0,10.511 7.272,17.943 17.411,17.943 10.246,0 17.36,-7.326 17.36,-17.837 0,-10.511 -7.22,-17.89 -17.412,-17.89 -10.246,0 -17.359,7.326 -17.359,17.784"
        fill="#f3c353"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,373.33013,288.99093)"
      />
      <path
        d="M 0,0 C 0,1.646 0.797,2.495 2.336,2.495 H 3.08 C 4.672,2.495 5.468,1.646 5.468,0 v -29.781 c 0,-1.646 -0.796,-2.495 -2.388,-2.495 H 2.336 C 0.797,-32.276 0,-31.427 0,-29.781 Z"
        fill="#f3c353"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,392.80467,269.03053)"
      />
      <path
        d="m 0,0 c 0,1.805 2.336,3.132 2.495,2.92 1.964,-2.867 5.627,-5.255 10.352,-5.255 4.778,0 7.485,1.964 7.485,5.361 0,3.875 -4.035,4.778 -8.6,5.84 -5.309,1.221 -10.829,3.078 -10.829,10.032 0,5.84 5.043,9.981 12.368,9.981 4.513,0 7.805,-1.274 9.715,-2.814 1.381,-1.061 2.071,-2.282 2.071,-3.291 0,-1.964 -2.442,-3.185 -2.549,-3.025 -1.804,2.6 -5.202,4.618 -9.183,4.618 -4.301,0 -7.114,-2.071 -7.114,-5.256 0,-3.769 3.344,-4.353 8.122,-5.468 C 20.173,12.316 25.8,10.034 25.8,3.397 25.8,-2.919 20.916,-6.848 12.899,-6.848 7.591,-6.848 3.822,-5.202 1.752,-3.291 0.584,-2.176 0,-1.008 0,0"
        fill="#f3c353"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,411.42507,303.57227)"
      />
      <path
        d="m 0,0 c -0.026,2.571 -1.766,4.466 -4.335,4.466 -2.623,0 -4.128,-2.051 -4.128,-4.466 0,-2.44 1.505,-4.465 4.128,-4.465 2.569,0 4.309,1.895 4.335,4.465 m -11.137,0 c 0,3.739 2.57,6.776 6.438,6.776 1.739,0 3.427,-0.597 4.647,-2.337 v 6.881 c 0,0.597 0.364,0.935 0.961,0.935 h 0.778 c 0.597,0 0.936,-0.338 0.936,-0.935 V -5.582 c 0,-0.597 -0.339,-0.934 -0.936,-0.934 H 0.909 c -0.597,0 -0.961,0.337 -0.961,0.934 v 1.117 c -1.22,-1.739 -2.908,-2.337 -4.647,-2.337 -3.868,0 -6.438,3.064 -6.438,6.802"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,315.15827,340.69013)"
      />
      <path
        d="m 0,0 v 6.412 c 0,0.597 0.337,0.935 0.935,0.935 h 0.778 c 0.598,0 0.961,-0.338 0.961,-0.935 V 0.025 c 0,-2.648 1.194,-3.634 2.57,-3.634 1.35,0 4.05,0.935 4.05,4.855 v 5.166 c 0,0.597 0.338,0.935 0.935,0.935 h 0.779 c 0.596,0 0.96,-0.338 0.96,-0.935 V -4.726 c 0,-0.597 -0.364,-0.934 -0.96,-0.934 h -0.779 c -0.597,0 -0.935,0.337 -0.935,0.934 v 1.61 C 8.723,-4.933 7.061,-5.946 4.958,-5.946 2.077,-5.946 0,-4.076 0,0"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,325.37107,341.83133)"
      />
      <path
        d="m 0,0 c -17.802,0 -32.284,14.482 -32.284,32.284 0,11.098 5.631,20.903 14.184,26.716 L -3.693,30.422 c 0.728,-1.442 2.183,-2.274 3.697,-2.274 0.625,0 1.261,0.143 1.857,0.444 2.04,1.027 2.86,3.514 1.832,5.554 l -14.397,28.559 c 3.355,1.184 6.948,1.862 10.704,1.862 17.803,0 32.285,-14.483 32.285,-32.283 C 32.285,14.482 17.803,0 0,0 M -82.723,32.284 C -82.723,14.482 -97.206,0 -115.008,0 c -17.803,0 -32.285,14.482 -32.285,32.284 0,17.8 14.482,32.283 32.285,32.283 10.667,0 20.114,-5.222 25.996,-13.22 l -27.994,-15.442 c -2,-1.103 -2.726,-3.618 -1.623,-5.618 0.754,-1.367 2.167,-2.139 3.624,-2.139 0.677,0 1.361,0.165 1.995,0.516 l 28.011,15.451 c 1.452,-3.669 2.276,-7.652 2.276,-11.831 M 0,72.838 c -5.095,0 -9.968,-0.954 -14.464,-2.676 l -15.135,30.022 c -0.703,1.394 -2.131,2.273 -3.693,2.273 h -0.009 l -15.251,-0.029 c -2.284,-0.004 -4.132,-1.859 -4.126,-4.143 0.003,-2.281 1.854,-4.128 4.134,-4.128 h 0.009 l 12.699,0.024 5.325,-10.564 -37.646,-20.766 -13.374,24.412 h 4.674 c 2.283,0 4.133,1.851 4.133,4.135 0,2.284 -1.85,4.135 -4.133,4.135 h -20.741 c -2.284,0 -4.134,-1.851 -4.134,-4.135 0,-2.284 1.85,-4.135 4.134,-4.135 h 6.635 L -75.4,58.855 -81.701,55.379 c -7.331,10.54 -19.524,17.459 -33.307,17.459 -22.361,0 -40.554,-18.193 -40.554,-40.554 0,-22.362 18.193,-40.555 40.554,-40.555 22.362,0 40.554,18.193 40.554,40.555 0,5.627 -1.154,10.988 -3.234,15.863 l 50.903,28.08 4.942,-9.802 C -33.084,59.208 -40.554,46.605 -40.554,32.284 -40.554,9.922 -22.361,-8.271 0,-8.271 c 22.361,0 40.554,18.193 40.554,40.555 0,22.361 -18.193,40.554 -40.554,40.554"
        fill="#1d1d1b"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,735.4648,440.98173)"
      />
      <path
        d="M 0,0 V -13.528"
        fill="none"
        stroke="#f3c353"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="6.923"
        transform="matrix(1.3333333,0,0,-1.3333333,735.1576,313.3704)"
      />
      <path
        d="M 0,0 V -13.528"
        fill="none"
        stroke="#f3c353"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="6.923"
        transform="matrix(1.3333333,0,0,-1.3333333,735.1576,463.43747)"
      />
      <path
        d="M 0,0 H -13.528"
        fill="none"
        stroke="#f3c353"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="6.923"
        transform="matrix(1.3333333,0,0,-1.3333333,819.2096,397.42253)"
      />
      <path
        d="M 0,0 H -13.529"
        fill="none"
        stroke="#f3c353"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="6.923"
        transform="matrix(1.3333333,0,0,-1.3333333,669.1432,397.42253)"
      />
      <path
        d="M 0,0 -9.566,-9.566"
        fill="none"
        stroke="#f3c353"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="6.923"
        transform="matrix(1.3333333,0,0,-1.3333333,794.5912,337.98893)"
      />
      <path
        d="M 0,0 -9.566,-9.566"
        fill="none"
        stroke="#f3c353"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="6.923"
        transform="matrix(1.3333333,0,0,-1.3333333,688.47787,444.1016)"
      />
      <path
        d="M 0,0 -9.566,9.566"
        fill="none"
        stroke="#f3c353"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="6.923"
        transform="matrix(1.3333333,0,0,-1.3333333,794.5912,456.8568)"
      />
    </SvgIcon>
  );
}
