import { Box, Typography } from '@mui/material';
import { useContext } from 'react';

import { AppContext } from '../app/context';
import Helmet from '../components/helmet';
import PageLayout from '../layouts/page';

function CookiesPolicy(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);
  const { origin } = window.location;

  return (
    <>
      <Helmet
        description={`Politique de cookies du site de challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
        title={`Politique de cookiess | Challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
      />
      <PageLayout title="Politique de cookies">
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          marginX="auto"
          maxWidth="100%"
          width="80ch"
        >
          <section>
            <Typography component={Box}>
              <p>
                Lors de votre navigation sur le site{' '}
                <a href={origin} rel="noreferrer" target="_blank">
                  {origin}
                </a>{' '}
                (ci-après le « Site »), des cookies, pixels et autres traceurs (ci-après désignés
                ensemble les « Cookies ») sont déposés sur votre navigateur ou sur votre terminal.
                Un Cookie est un petit fichier, souvent crypté, stocké dans votre navigateur ou
                votre terminal et identifié par un nom. Il est déposé lors de la consultation d’un
                site. Chaque fois que vous revenez sur le site en question, le Cookie est récupéré
                sur votre navigateur. Ainsi, chaque fois que vous consultez le site, le navigateur
                est reconnu. Le dépôt de ces Cookies est susceptible de nous permettre d’accéder à
                vos données de navigation et à des données à caractère personnel vous concernant.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              1. Identification des Cookies
            </Typography>
            <Typography component={Box}>
              <p>
                <strong>Cookies techniques et fonctionnels</strong>
                <br />
                <br />
                Les Cookies techniques et fonctionnels sont nécessaires au bon fonctionnement du
                Site et de l’Application et pour vous fournir nos services. Ils sont utilisés tout
                au long de votre navigation, afin de la faciliter et d’exécuter certaines fonctions.
                <br />
                Un Cookie technique peut par exemple être utilisé pour mémoriser vos réponses
                renseignées dans un formulaire ou encore vos préférences s’agissant de la langue ou
                de la présentation du Site et de l’Application, lorsque de telles options sont
                disponibles.
                <br />
                Nous utilisons les Cookies techniques et fonctionnels suivants :
              </p>
              <Box
                component="table"
                sx={{
                  borderCollapse: 'collapse',
                  borderSpacing: 'unset',
                  td: { border: '1px solid #000', padding: 1 },
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      <strong>Nom du Cookie</strong>
                    </td>
                    <td>Geovelo</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Fonction du Cookie</strong>
                    </td>
                    <td>
                      Ces cookies sont nécessaires au bon fonctionnement du site et rendent votre
                      navigation plus confortable. Ils vous permettent de vous authentifier plus
                      facilement sur notre plateforme. Ils permettent également de personnaliser
                      votre interface utilisateur.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Durée de conservation</strong>
                    </td>
                    <td>6 mois</td>
                  </tr>
                </tbody>
              </Box>
              <br />
              <p>
                <strong>Cookies analytiques</strong>
                <br />
                <br />
                Ces Cookies nous permettent de mesurer le nombre de visites, le nombre de pages vues
                et l’activité des utilisateurs. Ils peuvent le cas échéant collecter votre adresse
                IP pour déterminer la ville depuis laquelle vous vous connectez. Les Cookies
                analytiques nous permettent de générer des statistiques de fréquentation et de
                navigation de notre Site et Application afin d’améliorer nos performances. Les
                Cookies utilisés permettent également d’identifier les problèmes de navigation et à
                terme, de les régler.
                <br />
                Nous utilisons les Cookies analytiques suivants :
              </p>
              <Box
                component="table"
                sx={{
                  borderCollapse: 'collapse',
                  borderSpacing: 'unset',
                  td: { border: '1px solid #000', padding: 1 },
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      <strong>Nom du Cookie</strong>
                    </td>
                    <td>Matomo</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Fonction du Cookie</strong>
                    </td>
                    <td>
                      Les services de mesures d’audience permettent de générer des statistiques de
                      fréquentation et de navigation sur notre site. Les cookies utilisés permettent
                      également d’identifier les problèmes de navigation et à terme, de les régler.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Durée de conservation</strong>
                    </td>
                    <td>6 mois</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Lien vers la Politique de Cookies</strong>
                    </td>
                    <td>
                      <a href="https://matomo.org/privacy-policy/" rel="noreferrer" target="_blank">
                        https://matomo.org/privacy-policy/
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Box>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              2. Vos préférences en matière de Cookies
            </Typography>
            <Typography component={Box}>
              <p>
                <strong>Cookies pouvant être déposés sans consentement</strong>
                <br />
                <br />
                Certains Cookies ne nécessitent pas votre consentement, c’est le cas des :
              </p>
              <ul>
                <li>
                  Cookies techniques et fonctionnels qui sont nécessaires au fonctionnement du Site
                  ;
                </li>
                <li>
                  De certains Cookies de mesure d’audience ou des Cookies qui permettent de tester
                  des versions différentes du Site à des fins d’optimisation des choix éditoriaux.
                </li>
              </ul>
              <p>
                <strong>Le paramétrage de votre navigateur</strong>
                <br />
                <br />
                Il est également possible de paramétrer votre navigateur afin qu’il accepte ou
                refuse certains Cookies.
                <br />
                Chaque navigateur propose des modalités de configuration différentes.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              3. Identifiants publicitaires
            </Typography>
            <Typography component={Box}>
              <p>
                Des données personnelles vous concernant peuvent être collectées en lien avec votre
                identifiant publicitaire.
                <br />
                Chaque téléphone mobile possède un identifiant publicitaire qui lui est propre et
                qui permet aux annonceurs de diffuser de la publicité ciblée.
                <br />
                Android et Apple permettent aux mobinautes, dans les réglages, de maîtriser leur vie
                privée ce qui rend impossible le lien entre leur historique publicitaire et leurs
                prochaines navigations. Vous pouvez désactiver et réinitialiser à tout moment votre
                identifiant publicitaire, si vous ne souhaitez plus recevoir de publicités ciblées :
              </p>
              <ul>
                <li>
                  Instructions pour limiter le tracking publicitaire sur iOS :{' '}
                  <a
                    href="https://support.apple.com/fr-fr/HT205223"
                    rel="noreferrer"
                    target="_blank"
                  >
                    https://support.apple.com/fr-fr/HT205223
                  </a>
                </li>
                <li>
                  Instructions pour désactiver le ciblage publicitaire sur Android :{' '}
                  <a
                    href="https://support.google.com/ads/answer/2662922?hl=fr"
                    rel="noreferrer"
                    target="_blank"
                  >
                    https://support.google.com/ads/answer/2662922?hl=fr
                  </a>
                </li>
              </ul>
            </Typography>
          </section>
        </Box>
      </PageLayout>
    </>
  );
}

export default CookiesPolicy;
