import RegisterLayout, { TRegisterLayoutProps } from '../../layouts/register';
import { TEntity } from '../../models/entities';

import Option from './option';

function EntitySize<T extends string>({
  entity,
  sizeKey,
  setSizeKey,
  ...props
}: TRegisterLayoutProps & {
  entity: TEntity<T>;
  sizeKey: T | undefined;
  setSizeKey: (sizeKey: T) => void;
}): JSX.Element {
  const { sizes } = entity;

  return (
    <RegisterLayout
      hasMandatoryFields
      disableNext={sizeKey === undefined}
      title="Quelle est votre situation ? *"
      {...props}
    >
      {sizes.map(({ key, labelLong }) => (
        <Option active={sizeKey === key} key={key} onSelect={() => setSizeKey(key)}>
          {labelLong}
        </Option>
      ))}
    </RegisterLayout>
  );
}

export default EntitySize;
