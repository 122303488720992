import { Box, ButtonBase, Typography } from '@mui/material';
import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AppContext } from '../../app/context';
import Helmet from '../../components/helmet';
import PageLayout from '../../layouts/page';
import { TEntityType, entities } from '../../models/entities';

function Leaderboards(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const previous = pathname.indexOf('/classements-precedents') === 0;

  const links: { [key in TEntityType]?: string } = {
    city: `/classements${previous ? '-precedents' : ''}/collectivites`,
    company: `/classements${previous ? '-precedents' : ''}/employeurs`,
    association: `/classements${previous ? '-precedents' : ''}/associations`,
    school: `/classements${previous ? '-precedents' : ''}/etablissements-scolaires`,
    area: `/classements${previous ? '-precedents' : ''}/zones-geographiques`,
  };

  return (
    <>
      <Helmet
        description="Retrouvez les différents classements par territoire, par typologie et taille de structure, ainsi que les challenges nationaux."
        title={`Résultats ${previous ? '2023' : '2024'} | Challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
      />
      <PageLayout color="secondary" title={`Résultats ${previous ? '2023' : '2024'}`}>
        <Box display="flex" flexDirection="column" gap={5} width="100%">
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            marginX="auto"
            maxWidth="100%"
            width="80ch"
          >
            <Typography align="center" color="secondary" component={Box}>
              <p>
                Vous trouverez ci-dessous les différents classements par territoire, par typologie
                et taille de structure, sans oublier les challenges nationaux :
              </p>
            </Typography>
          </Box>
          <Box display="flex" flexWrap="wrap" gap={{ xs: 2, lg: 5 }} width="100%">
            {entities
              .filter(({ key }) => config !== 'mdv' || key !== 'area')
              .map(({ key, label, title, color, lightColor, icon, iconPosition, fullWidth }) => (
                <Box
                  key={key}
                  sx={{
                    background: `linear-gradient(to bottom, ${color} 0%, ${color} 90%, ${lightColor} 90%, ${lightColor} 100%)`,
                    borderRadius: 8,
                  }}
                  width={{ xs: '100%', lg: fullWidth ? '100%' : 'calc((100% - 40px) / 2)' }}
                >
                  <ButtonBase
                    component={Link}
                    sx={{
                      alignItems: 'center',
                      backgroundImage: { xs: 'none', md: `url(${icon})` },
                      backgroundPosition:
                        iconPosition === 'left' ? 'left 0 bottom 16px' : 'left 16px bottom 16px',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: iconPosition === 'left' ? '116px auto' : '100px auto',
                      borderRadius: 8,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      height: 160,
                      padding: 3,
                      width: '100%',
                    }}
                    to={links[key] || '/'}
                  >
                    <Typography
                      align="center"
                      color="#fff"
                      component="span"
                      fontSize={{ xs: '1.25rem', md: '1.25rem' }}
                      fontWeight={600}
                      textTransform="uppercase"
                    >
                      {label || 'Classement des'}
                    </Typography>
                    <Typography
                      align="center"
                      color="#fff"
                      component="span"
                      fontSize={{ xs: '1.75rem', md: '2rem' }}
                      fontWeight={600}
                      textTransform="uppercase"
                    >
                      {title}
                    </Typography>
                  </ButtonBase>
                </Box>
              ))}
          </Box>
        </Box>
      </PageLayout>
    </>
  );
}

export default Leaderboards;
