import { GeoveloWhiteIcon } from '@geovelo-frontends/commons';
import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../../app/context';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  MAVWhiteIcon,
  MDVWhiteIcon,
  XIcon,
} from '../../components/icons';

function Footer(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);

  return (
    <Box
      flexShrink={0}
      paddingX={4}
      paddingY={2}
      sx={{
        backgroundColor: '#212121',
        color: '#fff',
        a: { color: 'inherit', fontSize: '0.9rem', textDecoration: 'none' },
      }}
    >
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        gap={2}
        marginX="auto"
        maxWidth="100%"
        width={800}
      >
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          width={{ xs: '100%', sm: '66%' }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: '100%', md: 'calc((100% - 16px) / 2)' }}
          >
            <Typography
              component="a"
              href="https://faq.geovelo.app/fr/category/mai-a-velo-wt9rer/"
              rel="noreferrer"
              target="_blank"
            >
              FAQ
            </Typography>
            <Typography component={Link} to="/contact">
              Contact
            </Typography>
            <Typography
              component="a"
              href="https://drive.google.com/drive/folders/1QIq5727-JaqcEDtVP-BHUHLygHvETc6m"
              rel="noreferrer"
              target="_blank"
            >
              Presse et communication
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Typography component={Link} to="/cgu">
              CGU
            </Typography>
            <Typography component={Link} to="/mentions-legales">
              Mentions légales
            </Typography>
            <Typography component={Link} to="/politique-de-confidentialite">
              Politique de confidentialité
            </Typography>
            <Typography component={Link} to="/politique-de-cookies">
              Politique de cookies
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flexGrow={1} gap={2}>
          {config === 'mdv' ? (
            <MDVWhiteIcon sx={{ height: 'auto', width: 80, maxHeight: 40 }} />
          ) : (
            <MAVWhiteIcon sx={{ height: 'auto', width: 80 }} />
          )}
          <Box display="flex" flexDirection="column">
            <Typography fontSize="0.9rem">&copy; Geovelo {new Date().getFullYear()}</Typography>
            <Box alignItems="center" display="flex" gap={1} sx={{ a: { height: 20 } }}>
              <a href="https://geovelo.app" rel="noreferrer" target="_blank">
                <GeoveloWhiteIcon fontSize="small" />
              </a>
              <Typography>|</Typography>
              <a href="https://twitter.com/geovelofr" rel="noreferrer" target="_blank">
                <XIcon fontSize="small" />
              </a>
              <a href="https://www.facebook.com/geovelo" rel="noreferrer" target="_blank">
                <FacebookIcon fontSize="small" />
              </a>
              <a href="https://www.instagram.com/geovelofr" rel="noreferrer" target="_blank">
                <InstagramIcon fontSize="small" />
              </a>
              <a
                href="https://www.linkedin.com/company/la-compagnie-des-mobilit-s"
                rel="noreferrer"
                target="_blank"
              >
                <LinkedinIcon fontSize="small" />
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
