import { Autocomplete, Place } from '@geovelo-frontends/commons';

import RegisterLayout, { TRegisterLayoutProps } from '../../layouts/register';
import { TEntityType } from '../../models/entities';

function Address({
  entityType,
  address,
  setAddress,
  ...props
}: TRegisterLayoutProps & {
  entityType: TEntityType | 'individual' | undefined;
  address: Place | null;
  setAddress: (place: Place | null) => void;
}): JSX.Element {
  return (
    <RegisterLayout
      hasMandatoryFields
      disableNext={!address}
      title={
        entityType === 'city'
          ? 'Adresse centrale du territoire : *'
          : 'Adresse du site principal : *'
      }
      {...props}
    >
      <Autocomplete
        defaultValue={address}
        label="Recherchez votre adresse"
        onSelect={(place) => setAddress(place && 'addressDetail' in place ? place : null)}
        size="small"
      />
    </RegisterLayout>
  );
}

export default Address;
