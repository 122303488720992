import { Box, Typography } from '@mui/material';
import { useContext } from 'react';

import { AppContext } from '../app/context';
import Helmet from '../components/helmet';
import PageLayout from '../layouts/page';

function LegalNotice(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);
  const { origin } = window.location;

  return (
    <>
      <Helmet
        description={`Mentions légales du site de challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
        title={`Mentions légales | Challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
      />
      <PageLayout title="Mentions légales">
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          marginX="auto"
          maxWidth="100%"
          width="80ch"
        >
          <section>
            <Typography component="h2" variant="h6">
              Éditeur
            </Typography>
            <Typography component={Box}>
              <p>
                Le site internet{' '}
                <a href={origin} rel="noreferrer" target="_blank">
                  {origin}
                </a>{' '}
                est édité par :
              </p>
              <ul>
                <li>La société La Compagnie des Mobilités</li>
                <li>Société par actions simplifiée au capital social de 32 170 euros</li>
                <li>Immatriculée au RCS de Tours sous le numéro 521 563 742</li>
                <li>Numéro de TVA intracommunautaire : FR 49 521 563 742</li>
                <li>Siège social : 1 Impasse du Palais, 37 000 Tours</li>
                <li>
                  Adresse électronique : <a href="mailto:contact@geovelo.fr">contact@geovelo.fr</a>
                </li>
              </ul>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              Directeur de publication
            </Typography>
            <Typography component={Box}>
              <p>Monsieur Ronan Bouquet, Président de la société La Compagnie des Mobilités.</p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              Prestataire d'hébergement
            </Typography>
            <Typography component={Box}>
              <p>
                Le site internet{' '}
                <a href={origin} rel="noreferrer" target="_blank">
                  {origin}
                </a>{' '}
                est hébergé par :
              </p>
              <ul>
                <li>La société : SCALEWAY SAS BP 438 75366 PARIS CEDEX 08 FRANCE</li>
                <li>Siège social : 8 rue de la Ville l'Evêque, 75008 Paris</li>
                <li>
                  Téléphone : <a href="tel:+33184130000">+33 (0)1 84 13 00 00</a>
                </li>
              </ul>
            </Typography>
          </section>
        </Box>
      </PageLayout>
    </>
  );
}

export default LegalNotice;
