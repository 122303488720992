import { Button, Typography } from '@mui/material';
import { ReactNode } from 'react';

function Option({
  children,
  title,
  active,
  onSelect,
}: {
  active: boolean;
  children: ReactNode;
  title?: ReactNode;
  onSelect: () => void;
}): JSX.Element {
  return (
    <Button
      disableElevation
      color={active ? 'primary' : 'inherit'}
      onClick={onSelect}
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        textTransform: 'initial',
      }}
      variant={active ? 'contained' : 'outlined'}
    >
      {title && (
        <Typography component="span" fontWeight="bold">
          {title}
        </Typography>
      )}
      {children}
    </Button>
  );
}

export default Option;
