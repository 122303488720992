import { Search } from '@mui/icons-material';
import { Masonry } from '@mui/lab';
import { Box, InputAdornment, Skeleton, TextField, Typography } from '@mui/material';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AppContext } from '../../../app/context';
import Helmet from '../../../components/helmet';
import PageLayout from '../../../layouts/page';

function AreasLeaderboard({ previous }: { previous?: boolean }): JSX.Element {
  const [search, setSearch] = useState('');
  const [initialized, setInitialized] = useState(false);
  const {
    event: { config },
    zones: { france, regions, epcisMap, codesMap },
    actions: { getZones },
  } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (initialized && !france) getZones();

    setInitialized(true);
  }, [initialized]);

  if (config === 'mdv') navigate('/classements');

  return (
    <>
      <Helmet
        description="Retrouvez le classement général par zones géographiques."
        title={`Classement général par zones géographiques | Challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
      />
      <PageLayout title="Classement général par zones géographiques">
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          gap={5}
          marginTop={5}
          width="100%"
        >
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            margin="none"
            onChange={({ target: { value } }) => setSearch(value)}
            placeholder="Rechercher"
            size="small"
            sx={{ maxWidth: '100%', width: 300, fieldset: { borderRadius: 100 } }}
            value={search}
            variant="outlined"
          />
          <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
            {france && codesMap ? (
              <>
                {(!search || france.name.toLowerCase().indexOf(search.toLowerCase()) > -1) && (
                  <Box paddingBottom={5}>
                    <Typography
                      component={Link}
                      sx={({ palette }) => ({
                        '&&': { color: palette.primary.main, textDecoration: 'none' },
                      })}
                      to={
                        previous
                          ? `/classements-precedents/zones-geographiques/${codesMap[france.id]}`
                          : `/classements/zones-geographiques/${codesMap[france.id]}`
                      }
                      variant="h6"
                    >
                      {france.name}
                    </Typography>
                  </Box>
                )}
                {regions?.map(({ id, name }) => {
                  const children = (epcisMap?.[id] || []).filter(
                    ({ name: childName }) =>
                      !search || childName.toLowerCase().indexOf(search.toLowerCase()) > -1,
                  );

                  if (
                    search &&
                    children.length === 0 &&
                    name.toLowerCase().indexOf(search.toLowerCase()) === -1
                  )
                    return <Fragment key={id} />;

                  return (
                    <Box display="flex" flexDirection="column" gap={2} key={id} paddingBottom={5}>
                      <Typography
                        component={Link}
                        sx={({ palette }) => ({
                          '&&': { color: palette.primary.main, textDecoration: 'none' },
                        })}
                        to={
                          previous
                            ? `/classements-precedents/zones-geographiques/${codesMap[id]}`
                            : `/classements/zones-geographiques/${codesMap[id]}`
                        }
                        variant="h6"
                      >
                        {name}
                      </Typography>
                      {children.length > 0 && (
                        <Box display="flex" flexDirection="column">
                          {children.map(({ id: childId, name: childName }) => (
                            <Typography
                              component={Link}
                              key={childId}
                              sx={({ palette }) => ({
                                '&&': { color: palette.text.primary, textDecoration: 'none' },
                              })}
                              to={
                                previous
                                  ? `/classements-precedents/zones-geographiques/${codesMap[childId]}`
                                  : `/classements/zones-geographiques/${codesMap[childId]}`
                              }
                            >
                              {childName}
                            </Typography>
                          ))}
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </>
            ) : (
              <>
                {[1, 2, 3, 4, 5].map((_, index) => {
                  return (
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      key={index}
                      paddingBottom={5}
                    >
                      <Typography variant="h6">
                        <Skeleton variant="text" width={200} />
                      </Typography>
                      <Box display="flex" flexDirection="column">
                        {[1, 2, 3].map((_, childIndex) => (
                          <Typography key={childIndex}>
                            <Skeleton variant="text" width={200} />
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  );
                })}
              </>
            )}
          </Masonry>
        </Box>
      </PageLayout>
    </>
  );
}

export default AreasLeaderboard;
