import { SvgIcon, SvgIconProps } from '@mui/material';

export function MDVWhiteIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fill="none" height="816" viewBox="264 252 570 294" width="1056" {...props}>
      <path
        d="m 0,0 -27.384,71.104 c -0.235,0.705 2.115,2.586 4.701,2.703 2.469,0.235 4.584,-0.94 5.641,-4.113 L 5.877,6.346 29.03,69.694 c 1.057,3.408 3.291,4.348 5.407,4.113 2.467,-0.117 4.817,-1.998 4.583,-2.703 L 11.519,0 C 10.695,-2.116 9.403,-3.291 5.76,-3.291 2.116,-3.291 0.94,-2.116 0,0"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,314.5944,454.53907)"
      />
      <path
        d="m 0,0 v 65.815 c 0,3.057 1.881,5.173 4.818,5.173 h 43.721 c 2.704,0 4.114,-1.294 4.114,-3.645 v -0.586 c 0,-2.47 -1.41,-3.645 -4.114,-3.645 H 8.814 V 37.726 h 30.44 c 2.821,0 4.232,-1.291 4.232,-3.526 v -0.47 c 0,-2.468 -1.411,-3.642 -4.232,-3.642 H 8.814 V 2.821 h 40.665 c 2.703,0 4.114,-1.176 4.114,-3.526 v -0.588 c 0,-2.468 -1.411,-3.761 -4.114,-3.761 H 4.818 C 1.881,-5.054 0,-3.056 0,0"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,380.26107,451.24867)"
      />
      <path
        d="m 0,0 v 67.343 c 0,2.822 1.292,3.996 3.761,3.996 h 1.175 c 2.586,0 3.878,-1.174 3.878,-3.996 V 3.057 h 34.082 c 2.703,0 4.115,-1.294 4.115,-3.762 v -0.47 c 0,-2.47 -1.412,-3.879 -4.115,-3.879 H 4.818 C 1.88,-5.054 0,-3.056 0,0"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,468.336,451.24867)"
      />
      <path
        d="m 0,0 -7.549,22.828 h 3.235 L -0.733,11.573 C 0.258,8.495 1.111,5.715 1.732,3.061 h 0.081 c 0.645,2.634 1.59,5.506 2.625,8.476 L 8.335,22.828 H 11.52 L 3.284,0 Z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,410.4876,526.20707)"
      />
      <path
        d="m 0,0 -4.176,-4.85 h -2.125 l 3,4.85 z m -0.747,-13.424 c 0.03,1.876 -0.777,4.82 -4.15,4.82 -3.07,0 -4.377,-2.747 -4.617,-4.82 z m -8.804,-2.141 c 0.058,-4.028 2.64,-5.685 5.661,-5.685 2.152,0 3.469,0.381 4.579,0.838 l 0.528,-2.115 c -1.052,-0.477 -2.89,-1.045 -5.517,-1.045 -5.083,0 -8.139,3.352 -8.139,8.266 0,4.961 2.952,8.839 7.782,8.839 5.397,0 6.808,-4.688 6.808,-7.704 0,-0.623 -0.037,-1.072 -0.103,-1.425 z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,442.09053,495.21093)"
      />
      <path
        d="m 337.406,241.387 h 2.993 v -24.042 h -2.993 z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,0,816)"
      />
      <path
        d="m 0,0 c 2.887,0 5.043,2.669 5.043,6.387 0,2.769 -1.409,6.27 -4.987,6.27 -3.535,0 -5.072,-3.256 -5.072,-6.359 C -5.016,2.703 -2.938,0 -0.034,0 Z m -0.089,-2.23 c -4.489,0 -7.957,3.238 -7.957,8.434 0,5.481 3.641,8.687 8.22,8.687 4.758,0 7.98,-3.417 7.98,-8.404 0,-6.065 -4.265,-8.717 -8.21,-8.717 z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,468.65827,523.7252)"
      />
      <path
        d="m 0,0 c 4.708,0 7.388,4.291 7.388,9.488 0,4.563 -2.396,9.284 -7.279,9.284 -5.022,0 -7.482,-4.619 -7.481,-9.543 C -7.375,4.378 -4.691,0 -0.035,0 Z m 9.84,-5.364 c -3.107,0.787 -6.145,1.723 -8.818,2.635 -0.489,0.167 -0.939,0.316 -1.402,0.332 -5.483,0.201 -10.144,4.195 -10.144,11.551 0,7.311 4.502,12.013 10.723,12.013 6.257,0 10.337,-4.791 10.337,-11.538 0,-5.867 -2.743,-9.608 -6.567,-10.952 v -0.133 c 2.281,-0.565 4.788,-1.115 6.749,-1.46 z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,502.3412,523.50267)"
      />
      <path
        d="m 0,0 c 0,-1.716 0.058,-3.187 0.137,-4.485 h -2.658 l -0.161,2.681 h -0.082 c -0.762,-1.319 -2.512,-3.05 -5.475,-3.05 -2.591,0 -5.698,1.435 -5.698,7.174 v 9.572 h 2.995 V 2.844 c 0,-3.118 0.978,-5.243 3.696,-5.243 2.013,0 3.421,1.398 3.978,2.736 0.155,0.428 0.274,0.96 0.274,1.526 V 11.892 H 0 Z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,539.73573,520.22653)"
      />
      <path
        d="M 0,0 -4.177,-4.85 H -6.3 L -3.299,0 Z m -0.747,-13.424 c 0.028,1.876 -0.775,4.82 -4.149,4.82 -3.072,0 -4.379,-2.747 -4.618,-4.82 z m -8.805,-2.141 c 0.06,-4.028 2.641,-5.685 5.66,-5.685 2.154,0 3.469,0.381 4.582,0.838 l 0.53,-2.115 c -1.057,-0.477 -2.894,-1.045 -5.522,-1.045 -5.084,0 -8.136,3.352 -8.136,8.266 0,4.961 2.952,8.839 7.784,8.839 5.392,0 6.802,-4.688 6.802,-7.704 0,-0.623 -0.034,-1.072 -0.098,-1.425 z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,561.2916,495.21093)"
      />
      <path
        d="m 0,0 c 0,-0.368 0.041,-0.743 0.123,-1.082 0.564,-2.067 2.341,-3.485 4.51,-3.485 3.163,0 5.024,2.534 5.024,6.295 C 9.657,5.02 7.938,7.84 4.727,7.84 2.685,7.84 0.776,6.427 0.174,4.179 0.079,3.812 0,3.412 0,2.94 Z M -2.99,17.473 H 0 V 7.182 h 0.068 c 1.056,1.841 2.971,3.001 5.64,3.001 4.11,0 6.992,-3.381 6.982,-8.341 0,-5.875 -3.76,-8.78 -7.447,-8.78 -2.387,0 -4.298,0.926 -5.544,3.063 h -0.082 l -0.151,-2.694 h -2.594 c 0.079,1.128 0.138,2.771 0.138,4.227 z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,573.05467,517.44787)"
      />
      <path
        d="M 0,0 C 0.026,1.876 -0.777,4.82 -4.153,4.82 -7.221,4.82 -8.527,2.073 -8.767,0 Z m -8.804,-2.142 c 0.06,-4.027 2.639,-5.684 5.661,-5.684 2.148,0 3.466,0.381 4.577,0.838 l 0.529,-2.116 c -1.051,-0.476 -2.89,-1.044 -5.517,-1.044 -5.085,0 -8.139,3.352 -8.139,8.266 0,4.961 2.953,8.839 7.783,8.839 5.396,0 6.806,-4.688 6.806,-7.704 0,-0.623 -0.038,-1.072 -0.102,-1.425 z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,608.93093,513.10933)"
      />
      <path
        d="m 0,0 c -0.788,-0.383 -2.517,-0.949 -4.746,-0.949 -4.989,0 -8.255,3.359 -8.255,8.369 0,5.049 3.478,8.731 8.891,8.731 1.772,0 3.37,-0.442 4.18,-0.872 l -0.668,-2.287 c -0.74,0.389 -1.861,0.786 -3.517,0.786 -3.773,0 -5.847,-2.799 -5.837,-6.201 0,-3.795 2.466,-6.127 5.762,-6.127 1.682,0 2.831,0.423 3.682,0.787 z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,633.43227,525.414)"
      />
      <path
        d="m 0,0 c 0.367,-0.098 0.829,-0.135 1.389,-0.135 2.024,0 3.257,0.983 3.257,2.776 0,1.715 -1.214,2.546 -3.065,2.546 C 0.849,5.187 0.29,5.129 0,5.051 Z m -1.677,6.267 c 0.81,0.133 1.87,0.25 3.219,0.25 1.659,0 2.873,-0.386 3.645,-1.08 C 5.9,4.819 6.324,3.875 6.324,2.719 6.324,1.542 5.977,0.617 5.322,-0.059 4.435,-1.004 2.988,-1.485 1.35,-1.485 c -0.501,0 -0.964,0.02 -1.35,0.116 v -5.206 h -1.677 z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,279.4024,517.22267)"
      />
      <path
        d="m 0,0 -1.272,3.74 c -0.29,0.847 -0.482,1.619 -0.675,2.372 H -1.986 C -2.179,5.34 -2.391,4.55 -2.641,3.759 L -3.914,0 Z m -4.261,-1.312 -1.35,-4.087 h -1.735 l 4.415,12.995 h 2.025 L 3.529,-5.399 H 1.735 l -1.388,4.087 z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,297.16147,518.7904)"
      />
      <path
        d="m 0,0 h 1.717 c 1.792,0 2.93,0.983 2.93,2.468 0,1.678 -1.215,2.41 -2.989,2.43 C 0.849,4.898 0.271,4.82 0,4.742 Z m -1.677,5.919 c 0.849,0.174 2.063,0.27 3.219,0.27 1.794,0 2.951,-0.328 3.761,-1.059 0.655,-0.58 1.022,-1.466 1.022,-2.468 0,-1.718 -1.081,-2.855 -2.449,-3.316 v -0.06 C 4.878,-1.061 5.476,-1.985 5.784,-3.335 6.208,-5.147 6.517,-6.4 6.787,-6.902 H 5.052 C 4.839,-6.535 4.55,-5.418 4.185,-3.799 3.798,-2.005 3.104,-1.331 1.582,-1.271 H 0 v -5.631 h -1.677 z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,306.64067,516.7864)"
      />
      <path
        d="M 0,0 C 0.458,1.083 17.763,12.149 24.152,9.451 26.101,8.627 27.023,6.581 26.2,4.631 23.593,-1.542 1.875,-1.302 0.684,-0.799 -0.075,-0.479 -0.137,-0.325 0,0"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,403.02213,347.4336)"
      />
      <path
        d="m 0,0 c 0,1.857 1.221,3.025 3.132,3.025 h 1.433 c 2.123,0 2.707,-0.796 3.557,-2.76 L 16.245,-18.05 24.42,0.265 c 0.849,2.018 1.486,2.76 3.503,2.76 h 1.327 c 1.965,0 3.238,-1.168 3.238,-3.025 v -29.25 c 0,-1.646 -0.689,-2.496 -2.282,-2.496 h -0.743 c -1.54,0 -2.176,0.85 -2.176,2.496 v 25.215 l -7.592,-17.094 c -0.584,-1.38 -1.168,-2.336 -3.504,-2.336 -2.389,0 -2.866,0.956 -3.503,2.336 L 5.203,-4.194 V -29.25 c 0,-1.646 -0.638,-2.496 -2.23,-2.496 H 2.124 C 0.637,-31.746 0,-30.896 0,-29.25 Z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,277.76493,276.8164)"
      />
      <path
        d="m 0,0 c 0,7.645 -4.937,13.112 -11.891,13.112 -6.955,0 -11.839,-5.309 -11.839,-13.007 0,-7.644 4.938,-13.059 11.891,-13.059 C -4.884,-12.954 0,-7.645 0,0 m -29.25,0 c 0,10.511 7.272,17.942 17.411,17.942 10.246,0 17.36,-7.325 17.36,-17.837 0,-10.51 -7.22,-17.89 -17.412,-17.89 -10.246,0 -17.359,7.327 -17.359,17.785"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,371.56053,296.06907)"
      />
      <path
        d="M 0,0 C 0,1.646 0.797,2.495 2.336,2.495 H 3.08 C 4.672,2.495 5.468,1.646 5.468,0 v -29.781 c 0,-1.646 -0.796,-2.495 -2.388,-2.495 H 2.336 C 0.797,-32.276 0,-31.427 0,-29.781 Z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,391.0352,276.10867)"
      />
      <path
        d="m 0,0 c 0,1.805 2.336,3.132 2.495,2.92 1.964,-2.867 5.627,-5.255 10.352,-5.255 4.778,0 7.485,1.964 7.485,5.361 0,3.875 -4.035,4.778 -8.6,5.839 -5.309,1.222 -10.829,3.079 -10.829,10.033 0,5.84 5.043,9.98 12.368,9.98 4.513,0 7.805,-1.273 9.715,-2.813 1.381,-1.062 2.071,-2.283 2.071,-3.291 0,-1.964 -2.442,-3.185 -2.549,-3.025 -1.804,2.6 -5.202,4.617 -9.183,4.617 -4.301,0 -7.114,-2.07 -7.114,-5.255 0,-3.769 3.344,-4.353 8.122,-5.468 C 20.173,12.316 25.8,10.034 25.8,3.397 25.8,-2.919 20.916,-6.849 12.899,-6.849 7.591,-6.849 3.822,-5.202 1.752,-3.291 0.584,-2.176 0,-1.008 0,0"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,409.6556,310.6504)"
      />
      <path
        d="m 0,0 c -0.026,2.571 -1.766,4.466 -4.335,4.466 -2.623,0 -4.128,-2.051 -4.128,-4.466 0,-2.44 1.505,-4.465 4.128,-4.465 2.569,0 4.309,1.895 4.335,4.465 m -11.137,0 c 0,3.739 2.57,6.776 6.438,6.776 1.739,0 3.427,-0.597 4.647,-2.337 v 6.881 c 0,0.597 0.364,0.934 0.961,0.934 h 0.778 c 0.597,0 0.936,-0.337 0.936,-0.934 V -5.582 c 0,-0.597 -0.339,-0.934 -0.936,-0.934 H 0.909 c -0.597,0 -0.961,0.337 -0.961,0.934 v 1.117 c -1.22,-1.74 -2.908,-2.337 -4.647,-2.337 -3.868,0 -6.438,3.064 -6.438,6.802"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,313.38867,347.76827)"
      />
      <path
        d="M 0,0 V 6.412 C 0,7.01 0.337,7.347 0.935,7.347 h 0.778 c 0.598,0 0.961,-0.337 0.961,-0.935 V 0.025 c 0,-2.648 1.194,-3.633 2.57,-3.633 1.35,0 4.05,0.934 4.05,4.854 v 5.166 c 0,0.598 0.338,0.935 0.935,0.935 h 0.779 c 0.596,0 0.96,-0.337 0.96,-0.935 V -4.725 c 0,-0.598 -0.364,-0.935 -0.96,-0.935 h -0.779 c -0.597,0 -0.935,0.337 -0.935,0.935 v 1.609 C 8.723,-4.933 7.061,-5.945 4.958,-5.945 2.076,-5.945 0,-4.076 0,0"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,323.6016,348.91013)"
      />
      <path
        d="m 0,0 c -17.802,0 -32.284,14.482 -32.284,32.284 0,11.097 5.631,20.903 14.184,26.716 L -3.693,30.422 c 0.728,-1.442 2.183,-2.275 3.697,-2.275 0.625,0 1.261,0.144 1.857,0.445 2.04,1.027 2.86,3.514 1.832,5.554 l -14.397,28.559 c 3.355,1.184 6.948,1.862 10.704,1.862 17.803,0 32.285,-14.483 32.285,-32.283 C 32.285,14.482 17.803,0 0,0 M -82.724,32.284 C -82.724,14.482 -97.206,0 -115.008,0 c -17.803,0 -32.285,14.482 -32.285,32.284 0,17.8 14.482,32.283 32.285,32.283 10.667,0 20.114,-5.222 25.996,-13.22 l -27.994,-15.443 c -2,-1.102 -2.726,-3.617 -1.623,-5.617 0.754,-1.367 2.167,-2.14 3.624,-2.14 0.677,0 1.361,0.166 1.995,0.516 l 28.011,15.452 c 1.452,-3.669 2.275,-7.653 2.275,-11.831 M 0,72.837 c -5.095,0 -9.968,-0.953 -14.464,-2.675 l -15.135,30.021 c -0.703,1.395 -2.131,2.274 -3.693,2.274 h -0.009 l -15.251,-0.029 c -2.284,-0.004 -4.132,-1.86 -4.126,-4.143 0.003,-2.281 1.854,-4.128 4.134,-4.128 h 0.009 l 12.699,0.024 5.325,-10.564 -37.646,-20.767 -13.374,24.413 h 4.674 c 2.283,0 4.133,1.851 4.133,4.135 0,2.284 -1.85,4.135 -4.133,4.135 h -20.741 c -2.284,0 -4.134,-1.851 -4.134,-4.135 0,-2.284 1.85,-4.135 4.134,-4.135 h 6.635 L -75.4,58.855 -81.701,55.379 c -7.331,10.54 -19.524,17.458 -33.307,17.458 -22.361,0 -40.554,-18.192 -40.554,-40.553 0,-22.362 18.193,-40.555 40.554,-40.555 22.362,0 40.554,18.193 40.554,40.555 0,5.627 -1.154,10.988 -3.234,15.863 l 50.903,28.08 4.942,-9.802 C -33.084,59.208 -40.554,46.605 -40.554,32.284 -40.554,9.922 -22.361,-8.271 0,-8.271 c 22.361,0 40.554,18.193 40.554,40.555 0,22.361 -18.193,40.553 -40.554,40.553"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,733.69533,448.05987)"
      />
      <path
        d="M 0,0 V -13.529"
        fill="none"
        stroke="#ffffff"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="6.923"
        transform="matrix(1.3333333,0,0,-1.3333333,733.388,320.44853)"
      />
      <path
        d="M 0,0 V -13.528"
        fill="none"
        stroke="#ffffff"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="6.923"
        transform="matrix(1.3333333,0,0,-1.3333333,733.388,470.5156)"
      />
      <path
        d="M 0,0 H -13.528"
        fill="none"
        stroke="#ffffff"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="6.923"
        transform="matrix(1.3333333,0,0,-1.3333333,817.44013,404.50133)"
      />
      <path
        d="M 0,0 H -13.529"
        fill="none"
        stroke="#ffffff"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="6.923"
        transform="matrix(1.3333333,0,0,-1.3333333,667.37373,404.50133)"
      />
      <path
        d="M 0,0 -9.566,-9.566"
        fill="none"
        stroke="#ffffff"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="6.923"
        transform="matrix(1.3333333,0,0,-1.3333333,792.8216,345.06707)"
      />
      <path
        d="M 0,0 -9.566,-9.565"
        fill="none"
        stroke="#ffffff"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="6.923"
        transform="matrix(1.3333333,0,0,-1.3333333,686.70827,451.18093)"
      />
      <path
        d="M 0,0 -9.566,9.565"
        fill="none"
        stroke="#ffffff"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="1"
        strokeWidth="6.923"
        transform="matrix(1.3333333,0,0,-1.3333333,792.8216,463.93493)"
      />
      <path
        d="M 0,0 -4.271,-1.246 2.022,-14.701 10.978,4.467 H -5.723 c -1.506,0 -2.728,-1.222 -2.728,-2.729 V 1.035 l 8.374,0.439 z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,343.0644,498.5352)"
      />
      <path
        d="M 0,0 C -0.862,0 -1.56,0.698 -1.56,1.561 -1.56,2.422 -0.862,3.12 0,3.12 0.862,3.12 1.56,2.422 1.56,1.561 1.56,0.698 0.862,0 0,0 m 0,10.792 c -5.119,0 -9.143,-4.172 -9.143,-9.231 0,-5.031 4.083,-9.233 9.143,-9.233 5.06,0 9.143,4.202 9.143,9.233 0,5.059 -4.024,9.231 -9.143,9.231"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,371.2624,513.0352)"
      />
      <path
        d="m 0,0 c -5.724,0.675 -10.245,5.48 -10.245,11.429 0,6.391 5.267,11.569 11.629,11.569 6.362,0 11.629,-5.178 11.629,-11.569 0,-3.966 -2.01,-7.424 -5.046,-9.489 l 7.098,-2.208 c 0.002,0.046 0.007,0.091 0.007,0.137 v 22.643 c 0,1.507 -1.222,2.728 -2.729,2.728 H -5.623 L -16.612,2.237 h -2.271 l -7.915,16.485 -1.418,-0.413 v -18.44 c 0,-1.507 1.222,-2.728 2.729,-2.728 h 37.83 c 0.9,0 1.695,0.437 2.192,1.108 l -14.566,1.738 z"
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="matrix(1.3333333,0,0,-1.3333333,369.41733,526.23307)"
      />
    </SvgIcon>
  );
}
