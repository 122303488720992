import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Layout from '../layouts/default';
import Contact from '../pages/contact';
import CookiesPolicy from '../pages/cookies-policy';
import Embed from '../pages/embed';
import GCU from '../pages/gcu';
import Home from '../pages/home';
import Leaderboards from '../pages/leaderboards';
import AreasLeaderboard from '../pages/leaderboards/areas';
import AreaLeaderboard from '../pages/leaderboards/areas/area';
import AssociationsLeaderboard from '../pages/leaderboards/associations';
import CommunitiesLeaderboard from '../pages/leaderboards/communities';
import CompaniesLeaderboard from '../pages/leaderboards/companies';
import EducationalLeaderboard from '../pages/leaderboards/educational';
import LegalNotice from '../pages/legal-notice';
import PrivacyPolicy from '../pages/privacy-policy';
import Register from '../pages/register';
import SignIn from '../pages/sign-in';
import Statements from '../pages/statements';
import Thanks from '../pages/thanks';

function Router(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route element={<Register />} path="inscription" />
          <Route element={<AssociationsLeaderboard />} path="classements/associations" />
          <Route element={<CommunitiesLeaderboard />} path="classements/collectivites" />
          <Route element={<CompaniesLeaderboard />} path="classements/employeurs" />
          <Route element={<EducationalLeaderboard />} path="classements/etablissements-scolaires" />
          <Route element={<AreaLeaderboard />} path="classements/zones-geographiques/:zoneCode" />
          <Route element={<AreasLeaderboard />} path="classements/zones-geographiques" />
          <Route element={<Leaderboards />} path="classements" />
          <Route element={<AssociationsLeaderboard />} path="classements-precedents/associations" />
          <Route element={<CommunitiesLeaderboard />} path="classements-precedents/collectivites" />
          <Route element={<CompaniesLeaderboard />} path="classements-precedents/employeurs" />
          <Route
            element={<EducationalLeaderboard />}
            path="classements-precedents/etablissements-scolaires"
          />
          <Route
            element={<AreaLeaderboard />}
            path="classements-precedents/zones-geographiques/:zoneCode"
          />
          <Route
            element={<AreasLeaderboard previous />}
            path="classements-precedents/zones-geographiques"
          />
          <Route element={<Leaderboards />} path="classements-precedents" />
          <Route element={<Contact />} path="contact" />
          <Route element={<GCU />} path="cgu" />
          <Route element={<LegalNotice />} path="mentions-legales" />
          <Route element={<PrivacyPolicy />} path="politique-de-confidentialite" />
          <Route element={<CookiesPolicy />} path="politique-de-cookies" />
          <Route element={<Thanks />} path="merci" />
          <Route
            element={<Statements />}
            path="entites/:groupId/challenges/:challengeId/declarations"
          />
          <Route element={<SignIn />} path="connexion" />
          <Route element={<Navigate to="/" />} path="*" />
        </Route>
        <Route>
          <Route element={<Embed />} path="embed/:type/:zoneCode" />
          <Route element={<Embed />} path="embed/:type" />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
