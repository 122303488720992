import { Typography } from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../app/context';
import Helmet from '../components/helmet';
import PageLayout from '../layouts/page';

function Contact(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);

  return (
    <>
      <Helmet
        description={`Page de contact du site de challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
        title={`Contact | Challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
      />
      <PageLayout title="Contact">
        <iframe
          height="600px"
          referrerPolicy="origin"
          sandbox="allow-forms allow-popups allow-scripts allow-same-origin"
          src="https://plugins.crisp.chat/urn:crisp.im:contact-form:0/contact/1e1dfe74-a0e3-4f05-b0fd-c260f626189f"
          style={{ border: 0, outline: 0 }}
          title="Contact Form"
          width="100%"
        />
        <Typography maxWidth="80ch">
          Les données recueillies via ce formulaire sont traitées par Geovelo afin de gérer votre
          demande de contact. En savoir plus sur la gestion de vos données et vos droits :{' '}
          <Link to="/politique-de-confidentialite">Politique de confidentialité</Link>
        </Typography>
      </PageLayout>
    </>
  );
}

export default Contact;
