import { useContext } from 'react';

import { AppContext } from '../../app/context';
import LeaderboardLayout from '../../layouts/leaderboard';
import { companyEntity } from '../../models/entities';

function CompaniesLeaderboard(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);

  return (
    <LeaderboardLayout
      description="Retrouvez le classement des employeurs, en fonction du nombre d'employé·e·s."
      entity={companyEntity}
      title={`Classement des employeurs | Challenges ${
        config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
      } par Geovelo`}
    />
  );
}

export default CompaniesLeaderboard;
