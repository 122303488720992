import RegisterLayout, { TRegisterLayoutProps } from '../../layouts/register';

import Option from './option';

function SameLocation({
  sameLocation,
  setSameLocation,
  ...props
}: TRegisterLayoutProps & {
  sameLocation: boolean | undefined;
  setSameLocation: (sameLocation: boolean) => void;
}): JSX.Element {
  return (
    <RegisterLayout
      hasMandatoryFields
      disableNext={sameLocation === undefined}
      title="Les salariés de votre entreprise sont-ils principalement concentrés sur une seule région ? *"
      {...props}
    >
      <Option active={sameLocation === true} onSelect={() => setSameLocation(true)}>
        Oui, la majorité des salariés de mon entreprise résident dans la même région.
      </Option>
      <Option active={sameLocation === false} onSelect={() => setSameLocation(false)}>
        Non, mon entreprise dispose de plusieurs sites partout en France.
      </Option>
    </RegisterLayout>
  );
}

export default SameLocation;
