import { GeoveloTextIcon } from '@geovelo-frontends/commons';
import { Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AppContext } from '../../app/context';
import { MAVIcon, MDVIcon } from '../../components/icons';

function Header(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);
  const [navMenuAnchorEl, setNavMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [links, setLinks] = useState<{ key: string; label: string }[]>();
  const { pathname } = useLocation();

  useEffect(() => {
    setLinks(
      config === 'mdv'
        ? [
            { key: '/inscription', label: "S'inscrire" },
            { key: '/classements', label: 'Résultats 2024' },
          ]
        : [
            { key: '/inscription', label: "S'inscrire" },
            { key: '/classements', label: 'Résultats 2024' },
            { key: '/classements-precedents', label: 'Résultats 2023' },
          ],
    );
  }, [config]);

  function handleNavMenuClose() {
    setNavMenuAnchorEl(null);
  }

  const path =
    pathname.indexOf('/classements-precedents') === 0
      ? '/classements-precedents'
      : pathname.indexOf('/classements') === 0
        ? '/classements'
        : pathname;

  return (
    <AppBar color="default" elevation={0} sx={{ backgroundColor: '#fff' }} variant="outlined">
      <Toolbar>
        <Box
          alignItems="center"
          component={Link}
          display="flex"
          flexShrink={0}
          gap={2}
          sx={{ color: 'inherit', textDecoration: 'none' }}
          to="/"
        >
          <GeoveloTextIcon sx={{ height: 30, width: 'auto' }} />
          <Typography fontSize="1.5rem">/</Typography>
          {config === 'mdv' ? (
            <MDVIcon sx={{ height: 80, width: 'auto' }} />
          ) : (
            <MAVIcon sx={{ height: 30, width: 'auto' }} />
          )}
        </Box>
        <Box flexGrow={1} />
        <Hidden smDown>
          <Tabs
            component="nav"
            indicatorColor="secondary"
            sx={{ alignSelf: 'stretch', '.MuiTabs-flexContainer': { height: '100%' } }}
            textColor="secondary"
            value={links?.find(({ key }) => key === path) ? path : false}
          >
            {links ? (
              <>
                {links.map(({ key, label }) => (
                  <Tab
                    component={Link}
                    key={key}
                    label={label}
                    sx={{ fontSize: '1rem', fontWeight: 600, textTransform: 'initial' }}
                    to={key}
                    value={key}
                  />
                ))}
              </>
            ) : (
              <Skeleton variant="text" width={140} />
            )}
          </Tabs>
        </Hidden>
        <Hidden smUp>
          <IconButton
            aria-controls={Boolean(navMenuAnchorEl) ? 'basic-menu' : undefined}
            aria-expanded={Boolean(navMenuAnchorEl) ? 'true' : undefined}
            aria-haspopup="true"
            id="nav-menu-button"
            onClick={({ currentTarget }) => setNavMenuAnchorEl(currentTarget)}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={navMenuAnchorEl}
            id="nav-menu"
            MenuListProps={{
              'aria-labelledby': 'nav-menu-button',
            }}
            onClose={handleNavMenuClose}
            open={Boolean(navMenuAnchorEl)}
          >
            {links ? (
              <>
                {links.map(({ key, label }) => (
                  <MenuItem
                    component={Link}
                    key={key}
                    onClick={handleNavMenuClose}
                    selected={path === key}
                    to={key}
                  >
                    {label}
                  </MenuItem>
                ))}
              </>
            ) : (
              <Skeleton variant="text" width={140} />
            )}
          </Menu>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
