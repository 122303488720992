"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.conditionalBadgesMap = exports.conditionalBadges = exports.badgeLevelsMap = exports.badgeLevels = void 0;
var _icons = require("../components/icons");
const badgeLevels = exports.badgeLevels = ['cyclingDistance', 'regularity', 'contributor'];
const badgeLevelsMap = exports.badgeLevelsMap = {
  cyclingDistance: {
    bgColor: '#FFF5D0',
    color: '#BB8A1A',
    Icon: _icons.CyclingDistanceBadgeIcon,
    labelKey: 'geovelo.achievements.badge_titles.cycling_distance',
    progressKey: 'geovelo.achievements.progress.cycling_distance',
    nextLevelKey: 'geovelo.achievements.next_level.cycling_distance'
  },
  regularity: {
    bgColor: '#E7F4FA',
    color: '#1489BB',
    Icon: _icons.RegularityBadgeIcon,
    labelKey: 'geovelo.achievements.badge_titles.regularity',
    progressKey: 'geovelo.achievements.progress.regularity',
    nextLevelKey: 'geovelo.achievements.next_level.regularity'
  },
  contributor: {
    bgColor: '#FFDCED',
    color: '#F260A7',
    Icon: _icons.ContributorBadgeIcon,
    labelKey: 'geovelo.achievements.badge_titles.contributor',
    progressKey: 'geovelo.achievements.progress.contributor',
    nextLevelKey: 'geovelo.achievements.next_level.contributor'
  }
};
const conditionalBadges = exports.conditionalBadges = ['verticalGain', 'longDistance', 'nightBird'];
const conditionalBadgesMap = exports.conditionalBadgesMap = {
  longDistance: {
    bgColor: '#FFEEE0',
    color: '#FA823E',
    Icon: _icons.LongDistanceBadgeIcon,
    labelKey: 'geovelo.achievements.badge_titles.long_distance',
    progressKey: 'geovelo.achievements.progress.long_distance'
  },
  nightBird: {
    bgColor: '#EAE2FC',
    color: '#905EFA',
    Icon: _icons.NightBirdBadgeIcon,
    labelKey: 'geovelo.achievements.badge_titles.night_bird',
    progressKey: 'geovelo.achievements.progress.night_bird'
  },
  verticalGain: {
    bgColor: '#F8EFEA',
    color: '#A86D38',
    Icon: _icons.VerticalGainBadgeIcon,
    labelKey: 'geovelo.achievements.badge_titles.vertical_gain',
    progressKey: 'geovelo.achievements.progress.vertical_gain'
  }
};