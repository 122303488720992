import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material';
import { ReactNode, useContext } from 'react';

import { AppContext } from '../app/context';
import Helmet from '../components/helmet';

import PageLayout from './page';

export type TRegisterLayoutProps = {
  onNextClick?: () => void;
  onPrevClick?: () => void;
};

function RegisterLayout({
  children,
  hasMandatoryFields,
  title,
  disableNext,
  nextButtonLabel,
  prevButtonLabel,
  loading,
  onPrevClick,
  onNextClick,
}: {
  children: ReactNode;
  disableNext?: boolean;
  hasMandatoryFields?: boolean;
  loading?: boolean;
  nextButtonLabel?: ReactNode;
  prevButtonLabel?: ReactNode;
  title?: ReactNode;
} & TRegisterLayoutProps): JSX.Element {
  const {
    event: { current: event, config },
  } = useContext(AppContext);

  return (
    <>
      <Helmet
        description={`Inscrivez votre structure (collectivité, entreprise, association, école, etc.) au challenge d'activité ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        }.`}
        title={`S'inscrire | Challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
      />
      <PageLayout
        title={
          event ? (
            "S'inscrire à " + event?.title
          ) : (
            <Skeleton height={42} variant="rectangular" width={400} />
          )
        }
      >
        <Card sx={{ maxWidth: '100%', width: 'calc(80ch + 32px)' }} variant="outlined">
          {title && (
            <>
              <CardHeader title={title} />
              <Divider />
            </>
          )}
          <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {children}
            {hasMandatoryFields && <Typography variant="caption">* Champ obligatoire</Typography>}
          </CardContent>
          {(onPrevClick || onNextClick) && (
            <>
              <Divider />
              <CardActions sx={{ justifyContent: 'space-between' }}>
                {onPrevClick ? (
                  <Button
                    disableElevation
                    disabled={loading}
                    onClick={onPrevClick}
                    sx={{
                      backgroundColor: '#fff',
                      borderRadius: 100,
                      textTransform: 'initial',
                    }}
                    variant="outlined"
                  >
                    {prevButtonLabel || 'Précédent'}
                  </Button>
                ) : (
                  <Box />
                )}
                {onNextClick ? (
                  <Button
                    disableElevation
                    disabled={disableNext || loading}
                    onClick={onNextClick}
                    startIcon={
                      loading && <CircularProgress color="inherit" size={16} thickness={4} />
                    }
                    sx={{
                      backgroundColor: '#fff',
                      borderRadius: 100,
                      textTransform: 'initial',
                    }}
                    variant="outlined"
                  >
                    {nextButtonLabel || 'Suivant'}
                  </Button>
                ) : (
                  <Box />
                )}
              </CardActions>
            </>
          )}
        </Card>
      </PageLayout>
    </>
  );
}

export default RegisterLayout;
