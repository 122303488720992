import { useContext } from 'react';

import { AppContext } from '../../app/context';
import LeaderboardLayout from '../../layouts/leaderboard';
import { associationEntity } from '../../models/entities';

function AssociationsLeaderboard(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);

  return (
    <LeaderboardLayout
      description="Retrouvez le classement des associations, en fonction du nombre de membres."
      entity={associationEntity}
      title={`Classement des associations | Challenges ${
        config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
      } par Geovelo`}
    />
  );
}

export default AssociationsLeaderboard;
