import { TGeogroupType } from '@geovelo-frontends/commons';
import { Fragment, ReactNode, useContext } from 'react';

import { AppContext } from '../../app/context';
import RegisterLayout, { TRegisterLayoutProps } from '../../layouts/register';

import Option from './option';

const types: TGeogroupType[] = ['city', 'company', 'school', 'association'];

const typesMap: { [key in TGeogroupType]?: { description: string; title: string } } = {
  city: {
    title: 'Une collectivité / un territoire',
    description: "Le challenge s'adresse à mes habitants",
  },
  company: {
    title: 'Une entreprise / un employeur',
    description: "Le challenge s'adresse à mes employés / à mes agents",
  },
  school: {
    title: 'Une école / une institution éducative',
    description: "Le challenge s'adresse aux élèves et au personnel",
  },
  association: {
    title: 'Une association, ou autre',
    description: "Le challenge s'adresse à ma communauté",
  },
};

function EntityType({
  entityType,
  setEntityType,
  prevButtonLabel,
  ...props
}: TRegisterLayoutProps & {
  entityType: TGeogroupType | 'individual' | undefined;
  prevButtonLabel?: ReactNode;
  setEntityType: (entityType: TGeogroupType | 'individual') => void;
}): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);

  return (
    <RegisterLayout
      hasMandatoryFields
      disableNext={entityType === undefined}
      prevButtonLabel={prevButtonLabel}
      title="Quel type d'entité représentez-vous ? *"
      {...props}
    >
      {types.map((key) => {
        const type = typesMap[key];
        if (!type) return <Fragment key={key} />;

        const { title, description } = type;

        return (
          <Option
            active={entityType === key}
            key={key}
            onSelect={() => setEntityType(key)}
            title={title}
          >
            {description}
          </Option>
        );
      })}
      {config === 'mdv' && (
        <Option
          active={entityType === 'individual'}
          key={'individual'}
          onSelect={() => setEntityType('individual')}
          title="Un particulier"
        >
          Je m'inscris au défi individuel (pas incompatible avec les autres types de participation
          ci-dessus)
        </Option>
      )}
    </RegisterLayout>
  );
}

export default EntityType;
