import { useContext } from 'react';

import { AppContext } from '../../app/context';
import LeaderboardLayout from '../../layouts/leaderboard';
import { communityEntity } from '../../models/entities';

function CommunitiesLeaderboard(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);

  return (
    <LeaderboardLayout
      description="Retrouvez le classement des collectivités, en fonction du nombre d'habitants."
      entity={communityEntity}
      title={`Classement des collectivités | Challenges ${
        config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
      } par Geovelo`}
    />
  );
}

export default CommunitiesLeaderboard;
