import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import LeaderboardLayout from '../layouts/leaderboard';
import {
  TEntity,
  areaEntity,
  associationEntity,
  communityEntity,
  companyEntity,
  educationalEntity,
} from '../models/entities';

function Embed(): JSX.Element {
  const [entity, setEntity] = useState<TEntity>();
  const { type } = useParams<{ type: string; zoneCode?: string }>();

  useEffect(() => {
    switch (type) {
      case 'associations':
        setEntity(associationEntity);
        break;
      case 'collectivites':
        setEntity(communityEntity);
        break;
      case 'employeurs':
        setEntity(companyEntity);
        break;
      case 'etablissements-scolaires':
        setEntity(educationalEntity);
        break;
      case 'zones-geographiques':
        setEntity(areaEntity);
        break;
      default:
        break;
    }
  }, []);

  if (!entity) return <></>;

  return <LeaderboardLayout disableFiltering disableSharing entity={entity} />;
}

export default Embed;
