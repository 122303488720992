import 'maplibre-gl/dist/maplibre-gl.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/500.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import './index.css';
import App from './app';

const ele = document.getElementById('root');
if (ele) {
  const root = createRoot(ele);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
