import { Geogroup, UserGeogroup } from '@geovelo-frontends/commons';
import { Avatar, Box, ButtonBase, Chip, Radio, Typography } from '@mui/material';
import moment from 'moment';

function CommunityCard({
  selected,
  geogroup,
  onSelect,
  registered,
}: {
  geogroup: UserGeogroup;
  selected?: boolean;
  onSelect?: (geogroup: Geogroup) => void;
  registered: boolean;
}): JSX.Element {
  return (
    <Box
      alignItems="center"
      bgcolor={selected ? '#EEF9F4' : undefined}
      borderRadius={4}
      component={onSelect ? ButtonBase : 'div'}
      display="flex"
      gap={2}
      justifyContent="flex-start"
      onClick={onSelect ? () => onSelect(geogroup.group) : undefined}
      padding={2}
    >
      <Box flexShrink={0}>
        {geogroup.group.photo ? (
          <Avatar src={geogroup.group.photo} />
        ) : (
          <Avatar
            sx={({ palette }) => ({
              bgcolor: selected ? '#03825C' : onSelect ? undefined : palette.primary.main,
              color: selected || !onSelect ? palette.primary.contrastText : undefined,
            })}
          />
        )}
      </Box>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Typography
          align="left"
          color={selected ? '#03825C' : onSelect ? undefined : 'primary'}
          fontWeight={600}
          variant="body2"
        >
          {geogroup.group.title} (
          {`${geogroup.group.nbMembers || 0} membre${
            geogroup.group.nbMembers && geogroup.group.nbMembers > 1 ? 's' : ''
          }`}
          )
        </Typography>
        <Typography align="left" variant="caption">
          {`Rejoint le ${moment(geogroup.joinDate).format('LL')}`}
        </Typography>
      </Box>
      {registered && (
        <Chip label="Inscrit" size="small" sx={{ backgroundColor: '#EEF8F4', color: '#038B63' }} />
      )}
      {onSelect && (
        <Box flexShrink={0}>
          <Radio checked={selected || false} color="secondary" size="small" />
        </Box>
      )}
    </Box>
  );
}

export default CommunityCard;
