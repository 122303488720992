import { Button, Typography } from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../app/context';
import Helmet from '../components/helmet';
import PageLayout from '../layouts/page';

function Tkanks(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);
  return (
    <>
      <Helmet
        title={`Merci pour votre inscription | Challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
      />
      <PageLayout title="Merci pour votre inscription !">
        <Typography align="center" maxWidth="80ch">
          Nous vous envoyons un mail avec toutes les informations nécessaires pour faire de votre
          participation {config === 'mdv' ? 'au Mois du vélo' : 'à Mai à vélo'} un succès !
        </Typography>
        <Button
          disableElevation
          component={Link}
          sx={{
            '&&': { color: '#fff' },
            borderRadius: 100,
            textTransform: 'initial',
          }}
          to="/classements"
          variant="contained"
        >
          Voir les classements
        </Button>
      </PageLayout>
    </>
  );
}

export default Tkanks;
