import { useContext } from 'react';

import { AppContext } from '../../app/context';
import LeaderboardLayout from '../../layouts/leaderboard';
import { educationalEntity } from '../../models/entities';

function EducationalLeaderboard(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);

  return (
    <LeaderboardLayout
      description="Retrouvez le classement des établissements scolaires, en fonction du nombre d'élèves."
      entity={educationalEntity}
      title={`Classement des établissements scolaires | Challenges ${
        config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
      } par Geovelo`}
    />
  );
}

export default EducationalLeaderboard;
