import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../app/context';
import Helmet from '../components/helmet';
import PageLayout from '../layouts/page';

function PrivacyPolicy(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);
  const { origin } = window.location;

  return (
    <>
      <Helmet
        description={`Politique de confidentialité du site de challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
        title={`Politique de confidentialité | Challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
      />
      <PageLayout title="Politique de confidentialité">
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          marginX="auto"
          maxWidth="100%"
          width="80ch"
        >
          <section>
            <Typography component={Box}>
              <p>
                <strong>
                  Date d’entrée en vigueur : {config === 'mdv' ? '01/03/2024' : '01/05/2022'}
                </strong>
                <br />
                <br />
                Chez La Compagnie des Mobilités (Geovelo), la protection de vos données personnelles
                est une priorité.
                <br />
                Lors de votre utilisation du site{' '}
                <a href={origin} rel="noreferrer" target="_blank">
                  {origin}
                </a>{' '}
                (ci-après le « Site ») nous sommes amenés à recueillir des données à caractère
                personnel vous concernant.
                <br />
                Le but de cette politique est de vous informer sur les modalités selon lesquelles
                nous traitons ces données.
                <br />
                Dès lors que vous vous créez un compte sur ce Site, vous vous créez un compte sur
                l’application mobile Geovelo et vos données sont traitées et conservées conformément
                à la{' '}
                <a href="https://geovelo.app/fr/privacy-policy/" rel="noreferrer" target="_blank">
                  Politique de confidentialité de Geovelo
                </a>
                .
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              1. Qui est le responsable de traitement ?
            </Typography>
            <Typography component={Box}>
              <p>
                Le responsable de traitement est la société La Compagnie des Mobilités, société par
                actions simplifiée, immatriculée au RCS de Tours sous le n° 521 563 742 et dont le
                siège social est situé au 1 impasse du Palais, 37 000 Tours (ci-après « Nous »).
                <br />
                Il est à préciser que Geovelo propose un autre service gratuit qui ne repose pas sur
                la vente de données à caractère personnel mais sur la valorisation de données
                anonymes relatives à la circulation au sein de l’espace urbain afin d’en faire
                bénéficier les collectivités et leur permettre l’aménagement des villes à la
                pratique du vélo. Pour plus d’informations, consulter{' '}
                <a href="https://geovelo.app/fr/eula/" rel="noreferrer" target="_blank">
                  geovelo.app/fr/eula
                </a>
                .
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              2. Quelles données collectons-nous ?
            </Typography>
            <Typography component={Box}>
              <p>
                Une donnée à caractère personnel est une donnée qui permet d’identifier un individu
                directement ou par recoupement avec d’autres données.
                <br />
                Nous collectons des données qui relèvent des catégories suivantes :
              </p>
              <ul>
                <li>
                  Des données d’identification (par exemple : votre nom et votre adresse email) ;
                </li>
                <li>
                  Des données de connexion (par exemple votre adresse IP, vos logs de connexion) ;
                </li>
                <li>
                  Des données de navigation (par exemple : adresse IP, pages consultées, date et
                  heure de connexion, navigateur utilisé, système d’exploitation, user ID) ;
                </li>
                <li>
                  Toute information que vous souhaitez nous transmettre dans le cadre de votre
                  demande de contact.
                </li>
              </ul>
              <p>
                Les données obligatoires sont indiquées lorsque vous nous fournissez vos données.
                Elles sont signalées par un astérisque et sont nécessaires pour vous fournir nos
                services.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              3. Sur quelles bases légales, pour quelles finalités et pendant combien de temps
              conservons-nous vos données personnelles ?
            </Typography>
            <Typography component={Box}>
              <Box
                component="table"
                sx={{
                  borderCollapse: 'collapse',
                  borderSpacing: 'unset',
                  td: { border: '1px solid #000', padding: 1 },
                }}
              >
                <thead>
                  <tr>
                    <td>Finalité</td>
                    <td>Base légale</td>
                    <td>Durées de conservation</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Fournir nos services disponibles sur notre Site : Création de communautés,
                      participer à des challenges, effectuer des classements
                    </td>
                    <td>
                      Exécution de mesures précontractuelles prises à votre demande et/ou exécution
                      d’un contrat que vous avez souscrit.
                    </td>
                    <td>
                      Lorsque vous vous êtes créé un compte : vos données sont conservées pour toute
                      la durée de votre compte. Vous référer à la{' '}
                      <a
                        href="https://geovelo.app/fr/privacy-policy/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Politique de confidentialité de Geovelo
                      </a>{' '}
                      pour les durées de conservation applicables.
                    </td>
                  </tr>
                  <tr>
                    <td>Élaborer des statistiques de l’audience du site.</td>
                    <td>
                      Notre intérêt légitime à analyser la composition de notre clientèle et
                      améliorer nos services
                    </td>
                    <td>Les données sont conservées pendant 25 mois.</td>
                  </tr>
                  <tr>
                    <td>Adresser des newsletters, sollicitations et messages promotionnels</td>
                    <td>
                      Pour les clients : notre intérêt légitime à fidéliser et informer nos clients
                      de nos dernières actualités
                      <br />
                      Pour les prospects : votre consentement
                    </td>
                    <td>
                      Les données sont conservées pendant 3 ans à compter de votre dernier contact
                      avec Nous ou jusqu’au retrait de votre consentement.
                    </td>
                  </tr>
                  <tr>
                    <td>Répondre à vos demandes d’information</td>
                    <td>Notre intérêt légitime à répondre à vos demandes</td>
                    <td>
                      Les données sont conservées pendant le temps nécessaire au traitement de votre
                      demande d’information et supprimées une fois la demande d’information traitée.
                    </td>
                  </tr>
                  <tr>
                    <td>Gérer les demandes d’exercice de droits</td>
                    <td>
                      Notre intérêt légitime à répondre à vos demandes et à conserver un suivi de
                      celles-ci
                    </td>
                    <td>
                      Si nous vous demandons un justificatif d’identité : nous le conservons
                      seulement pendant le temps nécessaire à la vérification d’identité. Une fois
                      la vérification effectuée, le justificatif est supprimé.
                      <br />
                      Si vous exercez votre droit d’opposition à recevoir de la prospection : nous
                      conservons cette information pendant 3 ans.
                    </td>
                  </tr>
                </tbody>
              </Box>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              4. Qui sont les destinataires de vos données ?
            </Typography>
            <Typography component={Box}>
              <p>
                Auront accès à vos données à caractère personnel :<br />
                (i) Le personnel de notre société ;<br />
                (ii) Nos sous-traitants : prestataire d’hébergement, prestataire de gestion de
                l’événement, outil statistique, outils de monitoring des bugs, outil de support
                client.
                <br />
                (iii) Le cas échéant : les organismes publics et privés, exclusivement pour répondre
                à nos obligations légales.
              </p>
            </Typography>
          </section>
          {config !== 'mdv' && (
            <section>
              <Typography component="h2" variant="h6">
                5. Vos données sont-elles susceptibles d’être transférées hors de l’Union européenne
                ?
              </Typography>
              <Typography component={Box}>
                <p>
                  Vos données sont conservées et stockées pendant toute la durée des traitements sur
                  les serveurs de la société Scaleway situés en France.
                  <br />
                  Dans le cadre des outils que nous utilisons (voir article sur les destinataires
                  concernant nos sous-traitants), vos données sont susceptibles de faire l’objet de
                  transferts hors de l’Union européenne. Le transfert de vos données dans ce cadre
                  est sécurisé au moyen des outils suivants :
                </p>
                <ul>
                  <li>
                    Soit ces données sont transférées dans un pays ayant été jugé comme offrant un
                    niveau de protection adéquat par une décision de la Commission européenne ;
                  </li>
                  <li>
                    Soit nous avons conclu avec nos sous-traitants un contrat spécifique encadrant
                    les transferts de vos données en dehors de l’Union européenne, sur la base des
                    clauses contractuelles types entre un responsable de traitement et un
                    sous-traitant approuvées par la Commission européenne ;
                  </li>
                  <li>
                    Soit nous avons recours aux garanties appropriées prévues par la réglementation
                    applicable.
                  </li>
                </ul>
              </Typography>
            </section>
          )}
          <section>
            <Typography component="h2" variant="h6">
              {config === 'mdv' ? '5' : '6'}. Quels sont vos droits sur vos données ?
            </Typography>
            <Typography component={Box}>
              <p>Vous disposez des droits suivants s’agissant de vos données personnelles :</p>
              <ul>
                <li>
                  Droit à l’information : c’est justement la raison pour laquelle nous avons rédigé
                  la présente politique. Ce droit est prévu par les articles 13 et 14 du RGPD.
                </li>
                <li>
                  Droit d’accès : vous avez le droit d’accéder à tout moment à l’ensemble de vos
                  données personnelles, en vertu de l’article 15 du RGPD.
                </li>
                <li>
                  Droit de rectification : vous avez le droit de rectifier à tout moment vos données
                  personnelles inexactes, incomplètes ou obsolètes conformément à l’article 16 du
                  RGPD
                </li>
                <li>
                  Droit à la limitation : vous avez le droit d’obtenir la limitation du traitement
                  de vos données personnelles dans certains cas définis à l’article 18 du RGPD.
                </li>
                <li>
                  Droit à l’effacement : vous avez le droit d’exiger que vos données personnelles
                  soient effacées, et d’en interdire toute collecte future pour les motifs énoncés à
                  l’article 17 du RGPD
                </li>
                <li>
                  Droit d’introduire une réclamation auprès d’une autorité de contrôle compétente
                  (en France, la CNIL), si vous considérez que le traitement de vos données
                  personnelles constitue une violation des textes applicables. (Article 77 du RGPD)
                </li>
                <li>
                  Droit de définir des directives relatives à la conservation, à l’effacement et à
                  la communication de vos données personnelles après votre mort, conformément à
                  l’article 40-1 de la loi informatique et libertés.{' '}
                </li>
                <li>
                  Droit de retirer votre consentement à tout moment : pour les finalités fondées sur
                  le consentement, l’article 7 du RGPD dispose que vous pouvez retirer votre
                  consentement à tout moment. Ce retrait ne remettra pas en cause la légalité du
                  traitement effectué avant le retrait.
                </li>
                <li>
                  Droit à la portabilité : selon certaines conditions précisées à l’article 20 du
                  RGPD, vous avez le droit de recevoir les données personnelles que vous nous avez
                  fournies dans un format standard lisible par machine et d’exiger leur transfert au
                  destinataire de votre choix.
                </li>
                <li>
                  Droit d’opposition : en vertu de l’article 21 du RGPD, vous avez le droit de vous
                  opposer au traitement de vos données personnelles. Notez toutefois que nous
                  pourrons maintenir leur traitement malgré cette opposition, pour des motifs
                  légitimes ou la défense de droits en justice.
                </li>
              </ul>
              <p>
                Vous pouvez exercer ces droits en nous écrivant aux coordonnées ci-dessous. Nous
                pourrons vous demander à cette occasion de nous fournir des informations ou
                documents complémentaires pour justifier votre identité.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              {config === 'mdv' ? '6' : '7'}. Point de contact en matière de données personnelles
            </Typography>
            <Typography component={Box}>
              <p>
                Email de contact : <a href="mailto:support@geovelo.fr">support@geovelo.fr</a>
                <br />
                Adresse de contact : La Compagnie des Mobilités SAS, 1 impasse du Palais, 37 000
                Tours
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              {config === 'mdv' ? '7' : '8'}. Modifications
            </Typography>
            <Typography component={Box}>
              <p>
                Nous pouvons modifier à tout moment la présente politique, afin notamment de nous
                conformer à toutes évolutions réglementaires, jurisprudentielles, éditoriales ou
                techniques. Ces modifications s’appliqueront à la date d’entrée en vigueur de la
                version modifiée. Vous êtes donc invité à consulter régulièrement la dernière
                version de cette politique. Néanmoins, nous vous tiendrons informé(e) de toute
                modification significative de la présente politique de confidentialité.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component={Box}>
              <p>
                Annexe - <Link to="/politique-de-cookies">Politique de Cookies</Link>
                <br />
                <br />
                <a
                  href="https://drive.google.com/file/d/1UQbiSVpMwloEJZrcoOnN2Hy-kA8nk6Fb/view"
                  rel="noreferrer"
                  target="_blank"
                >
                  Télécharger la politique de confidentialité
                </a>
              </p>
            </Typography>
          </section>
        </Box>
      </PageLayout>
    </>
  );
}

export default PrivacyPolicy;
