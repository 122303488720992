import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../../app/context';
import LeaderboardLayout from '../../../layouts/leaderboard';
import { areaEntity } from '../../../models/entities';

function AreaLeaderboard(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);
  const navigate = useNavigate();

  if (config === 'mdv') navigate('/classements');

  return (
    <LeaderboardLayout
      description="Retrouvez le classement général par zones géographiques."
      entity={areaEntity}
      title={`Classement général par zones géographiques | Challenges ${
        config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
      } par Geovelo`}
    />
  );
}

export default AreaLeaderboard;
