import {
  CyclabilityZone,
  CyclabilityZoneService,
  GeogroupService,
  TAdministrativeLevel as _TAdministrativeLevel,
  backendAdministrativeLevels,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Autocomplete, FormControl, Select, TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { AppContext } from '../../app/context';
import { environment } from '../../environment';
import RegisterLayout, { TRegisterLayoutProps } from '../../layouts/register';

type TAdministrativeLevel = Exclude<_TAdministrativeLevel, 'country' | 'world'>;

const administrativeLevels: TAdministrativeLevel[] = ['region', 'department', 'epci', 'city'];

const administrativeLevelLabels: { [key in TAdministrativeLevel]?: string } = {
  region: 'Région',
  department: 'Département',
  epci: 'EPCI',
  city: 'Ville',
};

function Zone({
  cyclabilityZone,
  invitationLink,
  hasContract,
  setCyclabilityZone,
  setInvitationLink,
  setHasContract,
  ...props
}: TRegisterLayoutProps & {
  cyclabilityZone: CyclabilityZone | undefined;
  hasContract: boolean | undefined;
  invitationLink: string | undefined;
  setCyclabilityZone: (cyclabilityZone: CyclabilityZone | undefined) => void;
  setHasContract: (hasContract: boolean | undefined) => void;
  setInvitationLink: (invitationLink: string | undefined) => void;
}): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);
  const [administrativeLevel, setAdministrativeLevel] = useState<TAdministrativeLevel>('epci');
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState<CyclabilityZone[]>();
  const [loadingChallengesAndContracts, setLoadingChallengesAndContracts] = useState(false);
  const fetch = useMemo(
    () =>
      debounce(async (search: string, callback: (cyclabilityZones: CyclabilityZone[]) => void) => {
        if (locked.current) {
          callback([]);
          locked.current = false;
          return;
        }

        const { zones: cyclabilityZones } = await CyclabilityZoneService.getZones({
          administrativeLevel: backendAdministrativeLevels[administrativeLevel],
          rowsPerPage: 20,
          query:
            '{id,administrative_level,reference,country_code,name,geo_polygon,geo_polygon_simplified}',
          search,
        });

        callback(cyclabilityZones);
      }, 700),
    [administrativeLevel],
  );
  const locked = useRef(false);
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    setCyclabilityZone(undefined);
    setSearch('');
  }, [administrativeLevel]);

  useEffect(() => {
    let active = true;

    setSearchResults(undefined);
    if (!search) return;

    fetch(search, (cyclabilityZones: CyclabilityZone[]) => {
      if (active) setSearchResults(cyclabilityZones);
    });

    return () => {
      active = false;
    };
  }, [search, fetch]);

  useEffect(() => {
    setInvitationLink(undefined);
    setHasContract(undefined);

    if (cyclabilityZone) getChallengesAndContracts();

    return () => cancelPromises();
  }, [cyclabilityZone]);

  async function getChallengesAndContracts() {
    if (!cyclabilityZone) return;

    setLoadingChallengesAndContracts(true);

    try {
      const [challenges, contracts] = await cancellablePromise(
        Promise.all([
          CyclabilityZoneService.getEventChallenges(
            cyclabilityZone,
            config === 'mdv' ? environment.mdvEventId : environment.eventId,
          ),
          CyclabilityZoneService.getPartnerContracts(cyclabilityZone),
        ]),
      );

      if (challenges.length > 0) {
        const { link } = await GeogroupService.getInvitationLink(challenges[0].groupId);

        setInvitationLink(link);
      }

      setHasContract(contracts.length > 0);
      setLoadingChallengesAndContracts(false);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setLoadingChallengesAndContracts(false);
        setCyclabilityZone(undefined);
      }
    }
  }

  return (
    <RegisterLayout
      hasMandatoryFields
      disableNext={cyclabilityZone === undefined}
      loading={loadingChallengesAndContracts}
      title="Quel territoire représentez vous ? *"
      {...props}
    >
      <FormControl margin="none" size="small">
        <Select
          native
          disabled={loadingChallengesAndContracts}
          onChange={({ target: { value } }) =>
            setAdministrativeLevel(value as TAdministrativeLevel)
          }
          value={administrativeLevel}
        >
          {administrativeLevels.map((key) => (
            <option key={key} value={key}>
              {administrativeLevelLabels[key]}
            </option>
          ))}
        </Select>
      </FormControl>
      <Autocomplete
        disablePortal
        includeInputInList
        disabled={loadingChallengesAndContracts}
        forcePopupIcon={Boolean(search && !cyclabilityZone)}
        getOptionKey={(option) => option.id}
        getOptionLabel={(option) => option.name}
        loading={Boolean(search) && !cyclabilityZone}
        loadingText="Chargement"
        noOptionsText={search && 'Aucun résultat trouvé'}
        onChange={(_, value) => {
          locked.current = true;
          setCyclabilityZone(value || undefined);
        }}
        onInputChange={(_, value) => {
          setTimeout(() => {
            setSearch(value);
          }, 10);
        }}
        options={searchResults || []}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label="Recherchez votre territoire *"
            margin="none"
            size="small"
            variant="outlined"
          />
        )}
        value={cyclabilityZone || null}
      />
    </RegisterLayout>
  );
}

export default Zone;
