import { Challenge, GeogroupService, GeogroupStatement } from '@geovelo-frontends/commons';
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppContext } from '../app/context';
import Helmet from '../components/helmet';
import PageLayout from '../layouts/page';

type TGeogroupStatementsMap = { [key: string]: { distance: number; nbTraces: number } };

const headers = ['Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.', 'Dim.'];

function Statements(): JSX.Element {
  const [dates, setDates] = useState<Array<{ day: number; value: Date; week: number }>>();
  const [challenge, setChallenge] = useState<Challenge>();
  const [statements, setStatements] = useState<GeogroupStatement[]>();
  const [statementsMap, setStatementsMap] = useState<{ [key: string]: GeogroupStatement }>();
  const [updatedStatementsMap, setUpdatedStatementsMap] = useState<TGeogroupStatementsMap>();
  const [submitting, setSubmitting] = useState(false);

  const {
    event: { current: event, config },
    user: { current: currentUser },
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const { challengeId: _challengeId, groupId: _groupId } = useParams<{
    challengeId?: string;
    groupId?: string;
  }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (event) {
      const { startDate, endDate: _endDate } = event;
      const endDate = _endDate.toDate();
      const _dates: Array<{ day: number; value: Date; week: number }> = [];

      let date = startDate.toDate();
      date.setHours(12);
      let week = 1;
      while (date.getTime() <= endDate.getTime()) {
        const day = (date.getDay() + 6) % 7;

        _dates.push({ value: date, day, week });

        date = new Date(date);
        date.setDate(date.getDate() + 1);
        if (day === 6) ++week;
      }

      if (_dates.length > 0) setDates(_dates);
    }
  }, [event]);

  useEffect(() => {
    if (currentUser === null) navigate('/connexion', { state: { prevRoute: pathname } });
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && event) init();
  }, [event, currentUser]);

  useEffect(() => {
    if (statements) {
      const _statementsMap: { [key: string]: GeogroupStatement } = {};
      const _updatedStatementsMap: TGeogroupStatementsMap = {};

      statements.forEach((statement) => {
        const { strDate: key, distance, nbTraces } = statement;

        _statementsMap[key] = statement;
        _updatedStatementsMap[key] = { distance, nbTraces };
      });

      setStatementsMap(_statementsMap);
      setUpdatedStatementsMap(_updatedStatementsMap);
    }
  }, [statements]);

  async function init() {
    try {
      const groupId = parseInt(_groupId || '');
      const challengeId = parseInt(_challengeId || '');
      if (isNaN(groupId) || isNaN(challengeId)) throw new Error('invalid query params');

      const challenge = await GeogroupService.getChallenge(groupId, challengeId);

      setChallenge(challenge);
      getStatements(true);
    } catch (err) {
      console.error(err);
      if (typeof window !== 'undefined') window.alert("Vous n'avez pas accès à ce challenge");
      navigate('/');
    }
  }

  async function getStatements(init = false) {
    if (!event) return;

    try {
      const statements = await GeogroupService.getStatements({
        event,
        groupId: parseInt(_groupId || ''),
      });

      setStatements(statements);
    } catch (err) {
      if (init) {
        console.error(err);
        if (typeof window !== 'undefined')
          window.alert("Nous n'avons pas pu récupérer vos déclarations de trajets");
      } else {
        throw err;
      }
    }
  }

  function handleDistanceChange(key: string, value: string) {
    if (!updatedStatementsMap) return;

    const distance = parseFloat(value);

    if (!updatedStatementsMap[key])
      setUpdatedStatementsMap({ ...updatedStatementsMap, [key]: { distance, nbTraces: 0 } });
    else
      setUpdatedStatementsMap({
        ...updatedStatementsMap,
        [key]: { ...updatedStatementsMap[key], distance },
      });
  }

  function handleNbTracesChange(key: string, value: string) {
    if (!updatedStatementsMap) return;

    const nbTraces = parseInt(value);

    if (!updatedStatementsMap[key])
      setUpdatedStatementsMap({ ...updatedStatementsMap, [key]: { distance: 0, nbTraces } });
    else
      setUpdatedStatementsMap({
        ...updatedStatementsMap,
        [key]: { ...updatedStatementsMap[key], nbTraces },
      });
  }

  async function handleSubmit() {
    if (!challenge || !statementsMap || !updatedStatementsMap) return;

    const groupId = parseInt(_groupId || '');

    setSubmitting(true);

    try {
      await Promise.all(
        Object.keys(updatedStatementsMap).map((key) => {
          const oldStatement = statementsMap[key];
          const { distance, nbTraces } = updatedStatementsMap[key];

          if (!oldStatement)
            return GeogroupService.addStatement({
              groupId,
              strDate: key,
              distance: distance * 1000,
              nbTraces,
            });
          if (oldStatement.distance !== distance || oldStatement.nbTraces !== nbTraces)
            return GeogroupService.updateStatement(oldStatement.id, {
              groupId,
              strDate: key,
              distance: distance * 1000,
              nbTraces,
            });
        }),
      );

      await getStatements();
    } catch (err) {
      console.error(err);
      if (statements) setStatements([...statements]);
      if (typeof window !== 'undefined')
        window.alert("La déclaration de vos trajets n'a pas pu être effectuée");
    }

    setSubmitting(false);
  }

  if (currentUser === null) return <></>;

  return (
    <>
      <Helmet
        title={`Déclarez vos trajets | Challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
      />
      <PageLayout title="Déclarez vos trajets">
        {currentUser === undefined ? (
          <Box alignItems="center" display="flex" flexDirection="column" gap={2} paddingY={5}>
            <CircularProgress />
            <Typography>Récupération de l'utilisateur</Typography>
          </Box>
        ) : !dates || !challenge || !updatedStatementsMap ? (
          <Box alignItems="center" display="flex" flexDirection="column" gap={2} paddingY={5}>
            <CircularProgress />
            <Typography>Récupération des déclarations</Typography>
          </Box>
        ) : (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap={2}
            maxWidth="100%"
            width={1000}
          >
            <Typography align="center" maxWidth="80ch">
              Saisissez, pour les jours correspondants, la distance parcourue et le nombre de
              trajets effectués par les membres de votre communauté qui n'ont pas de smartphone. Si
              vous avez besoin d'estimer la distance d'un trajet, rendez-vous sur geovelo.app.{' '}
            </Typography>
            <Typography align="center" color="error" maxWidth="80ch">
              Veuillez ne pas ressaisir les trajets déjà pris en compte automatiquement par l'app
              Geovelo.
            </Typography>
            <Box display="flex" flexDirection="column" gap={2} width="100%">
              <StatementsActions disabled={submitting} onClick={handleSubmit} />
              <Grid
                container
                columns={7}
                sx={{
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: 4,
                  overflow: 'hidden',
                }}
              >
                {headers.map((header) => (
                  <Grid item key={header} xs={1}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      height={48}
                      justifyContent="center"
                      paddingX={1}
                    >
                      <Typography align="center" fontWeight={600}>
                        {header}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
                {new Array(dates[0].day).fill(null).map((_, index) => (
                  <Grid
                    item
                    key={index}
                    sx={{
                      borderLeft: index > 0 ? '1px solid rgba(0, 0, 0, 0.12)' : 0,
                      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                    xs={1}
                  >
                    <Box height="100%" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }} />
                  </Grid>
                ))}
                {dates.map(({ value, day }) => {
                  const key = value.toISOString().split('T')[0];
                  const statements = updatedStatementsMap[key];

                  return (
                    <Grid item key={key} xs={1}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        padding={1}
                        sx={{
                          borderLeft: day > 0 ? '1px solid rgba(0, 0, 0, 0.12)' : 0,
                          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                        }}
                      >
                        <Box alignSelf="flex-end" flexShrink={0}>
                          <Typography variant="caption">{value.getDate()}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" flexGrow={1} gap={1}>
                          <TextField
                            fullWidth
                            InputProps={{
                              endAdornment: <InputAdornment position="end">km</InputAdornment>,
                            }}
                            inputProps={{ step: 0.1, min: 0 }}
                            margin="none"
                            onChange={({ target: { value } }) => handleDistanceChange(key, value)}
                            size="small"
                            type="number"
                            value={statements?.distance || 0}
                            variant="outlined"
                          />
                          <TextField
                            fullWidth
                            InputProps={{
                              endAdornment: <InputAdornment position="end">trajets</InputAdornment>,
                            }}
                            inputProps={{ step: 1, min: 0 }}
                            margin="none"
                            onChange={({ target: { value } }) => handleNbTracesChange(key, value)}
                            size="small"
                            type="number"
                            value={statements?.nbTraces || 0}
                            variant="outlined"
                          />
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
                {new Array(6 - dates[dates.length - 1].day).fill(null).map((_, index) => (
                  <Grid
                    item
                    key={index}
                    sx={{
                      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                    xs={1}
                  >
                    <Box height="100%" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }} />
                  </Grid>
                ))}
              </Grid>
              {/* <Box overflow="hidden" position="relative">
                <Box
                  sx={({ palette }) => ({
                    border: `1px solid ${palette.primary.main}`,
                    borderRadius: 4,
                    opacity: !dates || submitting ? 0.2 : 1,
                    overflowX: !dates || submitting ? 'hidden' : 'auto',
                  })}
                >
                  <Grid className={!dates ? 'loading' : ''} color={color}>
                    {dates ? (
                      <>
                        {dates.map(({ value, day, week }) => {
                          const key = value.toISOString().split('T')[0];
                          const statements = updatedStatementsMap[key];

                          return (
                            <div
                              className={day === 6 ? 'sunday' : ''}
                              key={key}
                              style={{ gridColumn: day + 1, gridRow: week + 1 }}
                            >
                              <div style={{ alignSelf: 'center' }}>
                                <InputWrapper>
                                  <Input
                                    min={0}
                                    onChange={({ target: { value } }) =>
                                      handleDistanceChange(key, value)
                                    }
                                    step={0.1}
                                    type="number"
                                    value={statements?.distance || 0}
                                  />
                                  <span>km</span>
                                </InputWrapper>
                                <InputWrapper>
                                  <Input
                                    min={0}
                                    onChange={({ target: { value } }) =>
                                      handleNbTracesChange(key, value)
                                    }
                                    step={1}
                                    type="number"
                                    value={statements?.nbTraces || 0}
                                  />
                                  <span>trajets</span>
                                </InputWrapper>
                              </div>
                              <span>{value.getDate()}</span>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {new Array(35).fill(null).map((_, index) => (
                          <div
                            className={index % 7 === 6 ? 'sunday' : ''}
                            key={index}
                            style={{
                              gridColumn: (index % 7) + 1,
                              gridRow: Math.floor(index / 7) + 2,
                            }}
                          />
                        ))}
                      </>
                    )}
                  </Grid>
                </Box>
                {(!dates || submitting) && (
                  <Loader>
                    <Spinner color={color} />
                  </Loader>
                )}
              </Box> */}
              <StatementsActions disabled={submitting} onClick={handleSubmit} />
            </Box>
          </Box>
        )}
      </PageLayout>
    </>
  );
}

function StatementsActions(props: ButtonProps): JSX.Element {
  return (
    <Box alignItems="center" display="flex" gap={1} justifyContent="flex-end" width="100%">
      <Button
        disableElevation
        size="small"
        sx={{
          '&&': { color: '#fff' },
          borderRadius: 100,
          textTransform: 'initial',
        }}
        variant="contained"
        {...props}
      >
        Enregistrer
      </Button>
    </Box>
  );
}

export default Statements;
