import { TGeogroupType } from '@geovelo-frontends/commons';

import areasImg from '../assets/img/areas.svg';
import associationsImg from '../assets/img/associations.svg';
import communitiesImg from '../assets/img/communities.svg';
import companiesImg from '../assets/img/companies.svg';

export type TEntityType = TGeogroupType | 'area';

export type TEntity<T extends string = string> = {
  color: string;
  fullWidth?: boolean;
  icon?: string;
  iconPosition?: 'left';
  key: TEntityType;
  label?: string;
  lightColor: string;
  sizes: Array<{
    key: T;
    label: string;
    labelLong: string;
    labelSmallScreens?: string;
    max?: number;
    min?: number;
  }>;
  title: string;
};

export type TCommunitySize =
  | 'lessThan30000'
  | 'lessThan70000'
  | 'lessThan150000'
  | 'lessThan300000'
  | 'moreThan300000';

export const communityEntity: TEntity<TCommunitySize> = {
  key: 'city',
  title: 'Collectivités',
  color: '#A35D5A',
  lightColor: '#F8CCCA',
  icon: communitiesImg,
  iconPosition: 'left',
  sizes: [
    {
      key: 'lessThan30000',
      max: 29_999,
      label: '< 30 000 habitants',
      labelLong: 'Moins de 30 000 habitant·e·s',
      labelSmallScreens: '< 30 000',
    },
    {
      key: 'lessThan70000',
      min: 30_000,
      max: 69_999,
      label: '< 70 000 habitants',
      labelLong: 'Entre 30 000 et 70 000 habitant·e·s',
      labelSmallScreens: '< 70 000',
    },
    {
      key: 'lessThan150000',
      min: 70_000,
      max: 149_999,
      label: '< 150 000 habitants',
      labelLong: 'Entre 70 000 et 150 000 habitant·e·s',
      labelSmallScreens: '< 150 000',
    },
    {
      key: 'lessThan300000',
      min: 150_000,
      max: 299_999,
      label: '< 300 000 habitants',
      labelLong: 'Entre 150 000 et 300 000 habitant·e·s',
      labelSmallScreens: '< 300 000',
    },
    {
      key: 'moreThan300000',
      min: 300_000,
      label: '≥ 300 000 habitants',
      labelLong: '300 000 habitant·e·s ou plus',
      labelSmallScreens: '≥ 300 000',
    },
  ],
};

export type TCompanySize = 'lessThan50' | 'lessThan100' | 'lessThan500' | 'moreThan500';

export const companyEntity: TEntity<TCompanySize> = {
  key: 'company',
  title: 'Employeurs',
  color: '#67AC9C',
  lightColor: '#508379',
  icon: companiesImg,
  sizes: [
    {
      key: 'lessThan50',
      max: 50,
      label: '≤ 50 employé·e·s',
      labelLong: 'Moins de 51 employé·e·s',
      labelSmallScreens: '≤ 50',
    },
    {
      key: 'lessThan100',
      min: 51,
      max: 100,
      label: '≤ 101 employé·e·s',
      labelLong: 'Entre 51 et 100 employé·e·s',
      labelSmallScreens: '≤ 100',
    },
    {
      key: 'lessThan500',
      min: 101,
      max: 500,
      label: '≤ 501 employé·e·s',
      labelLong: 'Entre 101 et 500 employé·e·s',
      labelSmallScreens: '≤ 500',
    },
    {
      key: 'moreThan500',
      min: 501,
      label: '> 500 employé·e·s',
      labelLong: 'Plus de 500 employé·e·s',
      labelSmallScreens: '> 500',
    },
  ],
};

export type TAssociationSize = 'lessThan50' | 'lessThan100' | 'lessThan500' | 'moreThan500';

export const associationEntity: TEntity<TAssociationSize> = {
  key: 'association',
  title: 'Associations',
  color: '#F8AE48',
  lightColor: '#B9854B',
  icon: associationsImg,
  sizes: [
    {
      key: 'lessThan50',
      max: 50,
      label: '≤ 50 membres',
      labelLong: 'Moins de 51 membres',
      labelSmallScreens: '≤ 50',
    },
    {
      key: 'lessThan100',
      min: 51,
      max: 100,
      label: '≤ 101 membres',
      labelLong: 'Entre 51 et 100 membres',
      labelSmallScreens: '≤ 100',
    },
    {
      key: 'lessThan500',
      min: 101,
      max: 500,
      label: '≤ 501 membres',
      labelLong: 'Entre 101 et 500 membres',
      labelSmallScreens: '≤ 500',
    },
    {
      key: 'moreThan500',
      min: 501,
      label: '> 500 membres',
      labelLong: 'Plus de 500 membres',
      labelSmallScreens: '> 500',
    },
  ],
};

export type TEducationalSize = 'lessThan50' | 'lessThan100' | 'lessThan500' | 'moreThan500';

export const educationalEntity: TEntity<TEducationalSize> = {
  key: 'school',
  title: 'Établissements scolaires',
  color: '#67375C',
  lightColor: '#8F6B7E',
  sizes: [
    {
      key: 'lessThan50',
      max: 50,
      label: '≤ 50 élèves',
      labelLong: 'Moins de 51 élèves',
      labelSmallScreens: '≤ 50',
    },
    {
      key: 'lessThan100',
      min: 51,
      max: 100,
      label: '≤ 101 élèves',
      labelLong: 'Entre 51 et 100 élèves',
      labelSmallScreens: '≤ 100',
    },
    {
      key: 'lessThan500',
      min: 101,
      max: 500,
      label: '≤ 501 élèves',
      labelLong: 'Entre 101 et 500 élèves',
      labelSmallScreens: '≤ 500',
    },
    {
      key: 'moreThan500',
      min: 501,
      label: '> 500 élèves',
      labelLong: 'Plus de 500 élèves',
      labelSmallScreens: '> 500',
    },
  ],
};

export const areaEntity: TEntity<TAssociationSize> = {
  key: 'area',
  label: 'Classement général par',
  title: 'Zones géographiques',
  color: '#5E85C0',
  lightColor: '#5874A0',
  icon: areasImg,
  fullWidth: true,
  sizes: [
    {
      key: 'lessThan50',
      max: 50,
      label: '≤ 50 membres',
      labelLong: 'Moins de 51 membres',
      labelSmallScreens: '≤ 50',
    },
    {
      key: 'lessThan100',
      min: 51,
      max: 100,
      label: '≤ 101 membres',
      labelLong: 'Entre 51 et 100 membres',
      labelSmallScreens: '≤ 100',
    },
    {
      key: 'lessThan500',
      min: 101,
      max: 500,
      label: '≤ 501 membres',
      labelLong: 'Entre 101 et 500 membres',
      labelSmallScreens: '≤ 500',
    },
    {
      key: 'moreThan500',
      min: 501,
      label: '> 500 membres',
      labelLong: 'Plus de 500 membres',
      labelSmallScreens: '> 500',
    },
  ],
};

export const entities: TEntity[] = [
  communityEntity,
  companyEntity,
  associationEntity,
  educationalEntity,
  areaEntity,
];

export const entitiesMap: { [key in TGeogroupType]?: TEntity } = {
  association: associationEntity,
  city: communityEntity,
  company: companyEntity,
  school: educationalEntity,
};
