import { Box, Button, Divider, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import { AppContext } from '../../app/context';
import RegisterLayout, { TRegisterLayoutProps } from '../../layouts/register';

import SignIn from './sign-in';
import SignUp from './sign-up';
import SSO from './sso';

function Login({ ...props }: TRegisterLayoutProps): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);
  const [signingIn, signIn] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <RegisterLayout {...props} title={signingIn ? 'Connectez-vous' : 'Inscrivez-vous'}>
      <Typography>
        {signingIn ? 'Connectez-vous à votre compte' : 'Créez un compte'} Geovelo pour démarrer
        l'inscription de votre organisation {config === 'mdv' ? 'au Mois du vélo' : 'à Mai à vélo'}.
      </Typography>
      {signingIn ? (
        <Box alignItems="center" display="flex" flexDirection="row" gap={1} marginTop={-1}>
          <Typography
            align="center"
            color="textSecondary"
            component="p"
            lineHeight="100%"
            variant="caption"
          >
            Pas encore de compte ?
          </Typography>
          <Button
            color="primary"
            onClick={() => signIn(false)}
            size="small"
            sx={{ textTransform: 'initial', fontSize: '1rem' }}
            variant="text"
          >
            Inscrivez-vous
          </Button>
        </Box>
      ) : (
        <Box alignItems="center" display="flex" flexDirection="row" gap={1} marginTop={-1}>
          <Typography
            align="center"
            color="textSecondary"
            component="p"
            lineHeight="100%"
            variant="caption"
          >
            Vous avez déjà un compte ?{' '}
          </Typography>
          <Button
            color="primary"
            onClick={() => signIn(true)}
            size="small"
            sx={{ textTransform: 'initial', fontSize: '1rem' }}
            variant="text"
          >
            Connectez-vous
          </Button>
        </Box>
      )}
      <SSO setLoading={setLoading} signUp={!signingIn} />
      <Divider sx={{ width: '100%' }}>
        <Typography>ou</Typography>
      </Divider>
      {signingIn ? <SignIn loading={loading} /> : <SignUp loading={loading} />}
    </RegisterLayout>
  );
}

export default Login;
