import {
  AppleIcon,
  AuthService,
  FacebookIcon,
  GoogleIcon,
  useSignIn,
} from '@geovelo-frontends/commons';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { AppContext } from '../app/context';
import Helmet from '../components/helmet';
import PageLayout from '../layouts/page';

interface IFormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

function SignIn(): JSX.Element {
  const [passwordShowed, showPassword] = useState(false);
  const {
    event: { config },
    user: { current: currentUser },
    actions: { setCurrentUser },
  } = useContext(AppContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    isSubmitting,
    values,
    touched,
    errors,
    isValid,
    setSubmitting,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik<IFormValues>({
    initialValues: { email: '', password: '', rememberMe: false },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("L'email est invalide").required("L'email est requis"),
    }),
    enableReinitialize: true,
    onSubmit,
  });
  const { facebookSignIn, googleSignIn, appleSignIn } = useSignIn({
    setLoading: setSubmitting,
  });

  useEffect(() => {
    if (currentUser) navigate(state?.prevRoute || '/');
  }, [currentUser]);

  async function handleFacebookSignIn() {
    try {
      const user = await facebookSignIn('fr');

      setCurrentUser(user);
    } catch (err) {
      console.error(err);
    }
  }

  async function handleGoogleSignIn() {
    try {
      const user = await googleSignIn('fr');

      setCurrentUser(user);
    } catch (err) {
      console.error(err);
    }
  }

  async function handleAppleSignIn() {
    try {
      const user = await appleSignIn('fr');

      setCurrentUser(user);
    } catch (err) {
      console.error(err);
    }
  }

  async function onSubmit(
    { email, password, rememberMe }: IFormValues,
    { setSubmitting, setErrors }: FormikHelpers<IFormValues>,
  ) {
    setSubmitting(true);

    try {
      const user = await AuthService.signInWithEmail(email, password, rememberMe, 'fr');

      setCurrentUser(user);
    } catch (err) {
      setErrors({
        email:
          typeof err === 'object' && err && 'code' in err && err.code === 429
            ? 'Trop de tentatives, veuillez réessayer plus tard.'
            : 'Identifiants invalides',
      });
      setFieldValue('password', '', false);
      setSubmitting(false);
    }
  }

  return (
    <>
      <Helmet
        title={`Connexion | Challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
      />
      <PageLayout title="Connexion">
        {currentUser === undefined ? (
          <Box alignItems="center" display="flex" flexDirection="column" gap={2} paddingY={5}>
            <CircularProgress />
            <Typography>Récupération de l'utilisateur</Typography>
          </Box>
        ) : isSubmitting ? (
          <Box alignItems="center" display="flex" flexDirection="column" gap={2} paddingY={5}>
            <CircularProgress />
            <Typography>Connexion de l'utilisateur</Typography>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap={3} maxWidth="100%" width={400}>
            <Box
              alignItems="center"
              alignSelf="stretch"
              display="flex"
              flexDirection="column"
              gap={2}
              justifyContent="stretch"
            >
              <SSOButton
                disabled={isSubmitting}
                onClick={handleGoogleSignIn}
                size="large"
                variant="outlined"
              >
                <GoogleIcon />
                Se connecter avec Google
              </SSOButton>
              <SSOButton
                disabled={isSubmitting}
                onClick={handleFacebookSignIn}
                size="large"
                variant="outlined"
              >
                <FacebookIcon sx={{ color: '#485a96' }} />
                Se connecter avec Facebook
              </SSOButton>
              <SSOButton
                disabled={isSubmitting}
                onClick={handleAppleSignIn}
                size="large"
                variant="outlined"
              >
                <AppleIcon />
                Se connecter avec Apple
              </SSOButton>
            </Box>
            <Typography align="center" fontWeight={600}>
              ou
            </Typography>
            <Box
              component="form"
              display="flex"
              flexDirection="column"
              gap={2}
              onSubmit={handleSubmit}
            >
              <TextField
                disabled={isSubmitting}
                error={Boolean(errors.email && touched.email)}
                helperText={touched.email && errors.email}
                id="email"
                margin="none"
                name="email"
                onChange={handleChange}
                placeholder="Email"
                size="small"
                type="email"
                value={values.email}
                variant="filled"
              />
              <TextField
                disabled={isSubmitting}
                id="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => showPassword(!passwordShowed)}
                        size="small"
                      >
                        {passwordShowed ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                margin="none"
                name="password"
                onChange={handleChange}
                placeholder="Mot de passe"
                size="small"
                type={passwordShowed ? 'text' : 'password'}
                value={values.password}
                variant="filled"
              />
              <FormControl component="fieldset" disabled={isSubmitting} margin="none">
                <FormControlLabel
                  disableTypography
                  control={
                    <Checkbox
                      color="primary"
                      name="rememberMe"
                      onChange={handleChange}
                      value={values.rememberMe}
                    />
                  }
                  label={<Typography variant="caption">Se souvenir de moi</Typography>}
                />
              </FormControl>
              <Button
                color="primary"
                disabled={isSubmitting || !isValid || !values.email}
                size="large"
                type="submit"
                variant="contained"
              >
                Se connecter
              </Button>
            </Box>
            <Box display="flex" flexDirection="row">
              <Typography align="center" color="textSecondary" component="p" variant="caption">
                <a href="https://geovelo.app/fr/forgot-password/" rel="noreferrer" target="_blank">
                  Mot de passe oublié ?
                </a>
              </Typography>
            </Box>
          </Box>
        )}
      </PageLayout>
    </>
  );
}

function SSOButton(props: ButtonProps) {
  return (
    <Button sx={{ border: '1px solid #cfcfcf', gap: 1, height: 60, width: '100%' }} {...props} />
  );
}

export default SignIn;
