import { TGeogroupType } from '@geovelo-frontends/commons';
import { useSearchParams } from 'react-router-dom';

type TParams<TSize extends string> = {
  search?: string | null;
  size?: TSize | null;
  types?: TGeogroupType[];
};

function useQueryParams<TSize extends string>(): {
  searchParams: URLSearchParams;
  get: <T extends string>(key: string, allowedValues?: T[]) => T[] | undefined;
  update: (props: TParams<TSize>) => void;
} {
  const [searchParams, setSearchParams] = useSearchParams();

  function get<T extends string>(key: string, allowedValues?: T[]): T[] | undefined {
    const values = searchParams.get(key)?.split(',');
    if (!values) return;

    return allowedValues?.filter((value) => values.includes(value)) || (values as T[]);
  }

  function update({ types, size, search }: TParams<TSize>) {
    const params: Record<string, string> = {};
    for (const [key, value] of searchParams) {
      params[key] = value;
    }

    if (types && types.length > 0) params.types = types.join(',');
    else if (types && types.length === 0) delete params.types;

    if (size) params.size = size;
    else if (size === null) delete params.size;

    if (search) params.search = search;
    else if (search === null || search === '') delete params.search;

    setSearchParams(params, { replace: true });
  }

  return { searchParams, get, update };
}

export default useQueryParams;
