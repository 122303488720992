import { FileInput, TFile } from '@geovelo-frontends/commons';
import { Typography } from '@mui/material';

import RegisterLayout, { TRegisterLayoutProps } from '../../layouts/register';
import { TEntityType } from '../../models/entities';

function Logo({
  entityType,
  icon,
  setIcon,
  ...props
}: TRegisterLayoutProps & {
  entityType: TEntityType | 'individual' | undefined;
  icon: TFile | undefined;
  setIcon: (icon?: TFile) => void;
}): JSX.Element {
  return (
    <RegisterLayout
      nextButtonLabel="S'inscrire"
      title={
        entityType === 'city'
          ? 'Logo de votre territoire :'
          : entityType === 'company'
            ? 'Logo de votre entreprise :'
            : entityType === 'school'
              ? 'Logo de votre école / institution éducative :'
              : entityType === 'association'
                ? 'Logo de votre communauté :'
                : ''
      }
      {...props}
    >
      <Typography>Ce logo sera celui utilisé pour la communauté Geovelo</Typography>
      <FileInput file={icon} onChange={setIcon} type="image" />
    </RegisterLayout>
  );
}

export default Logo;
