import { TextField } from '@mui/material';

import RegisterLayout, { TRegisterLayoutProps } from '../../layouts/register';
import { TEntityType } from '../../models/entities';

function Name({
  entityType,
  name,
  setName,
  ...props
}: TRegisterLayoutProps & {
  entityType: TEntityType | 'individual' | undefined;
  name: string | undefined;
  setName: (name: string) => void;
}): JSX.Element {
  return (
    <RegisterLayout
      hasMandatoryFields
      disableNext={!name || name.length > 60}
      title={
        entityType === 'company'
          ? "Quelle est le nom de l'employeur que vous représentez ? *"
          : entityType === 'school'
            ? "Quelle est le nom de l'école / l'institution éducative que vous représentez ? *"
            : entityType === 'association'
              ? "Quelle est le nom de l'association que vous représentez ? *"
              : 'Quelle est le nom du territoire que vous représentez ? *'
      }
      {...props}
    >
      <TextField
        error={!!name && name.length > 60}
        helperText="60 caractères maximum"
        inputProps={{ maxLength: 60 }}
        label={
          entityType === 'company'
            ? "Nom de l'employeur"
            : entityType === 'school'
              ? "Nom de l'école / l'institution éducative"
              : entityType === 'association'
                ? "Nom de l'association"
                : 'Nom du territoire'
        }
        margin="none"
        onChange={({ currentTarget: { value } }) => setName(value)}
        size="small"
        value={name}
        variant="outlined"
      />
    </RegisterLayout>
  );
}

export default Name;
