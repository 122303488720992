import { useTracker } from '@geovelo-frontends/commons';
import { Box, Toolbar } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Footer from './footer';
import Header from './header';

function Layout(): JSX.Element {
  const { trackPageView } = useTracker();
  const { pathname } = useLocation();

  useEffect(() => {
    trackPageView();
  }, [pathname]);

  return (
    <>
      <Header />
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Toolbar />
        <Box flexGrow={1} sx={{ backgroundColor: '#f5f5f5' }}>
          <Outlet />
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default Layout;
