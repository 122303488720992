import { Helmet as ReactHelmet } from 'react-helmet-async';

function Helmet({ title, description }: { description?: string; title: string }): JSX.Element {
  const { origin, pathname } = window.location;
  const url = `${origin}${pathname}`;
  // const description =
  //  "Mai à vélo est l'occasion pour les associations, collectivités, établissements scolaires, entreprises et autres établissements français, de se défier au cours d'un challenge d'activité à vélo, local, national et unique en son genre !";

  return (
    <ReactHelmet>
      <title>{title}</title>
      {description && <meta content={description} name="description" />}
      <link href="/favicon.svg" rel="icon" />
      <meta content="/banner.jpg" property="image" />
      <meta content="Geovelo" name="author" />

      <meta content="website" property="og:type" />
      <meta content={url} property="og:url" />
      <meta content={title} property="og:title" />
      {description && <meta content={description} property="og:description" />}
      <meta content="/banner.png" property="og:image" />

      <meta content="summary_large_image" property="twitter:card" />
      <meta content={url} property="twitter:url" />
      <meta content={title} property="twitter:title" />
      {description && <meta content={description} property="twitter:description" />}
      <meta content="/banner.jpg" property="twitter:image" />
    </ReactHelmet>
  );
}

export default Helmet;
