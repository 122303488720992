import { Geogroup, HttpService } from '@geovelo-frontends/commons';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../../app/context';
import CommunityCard from '../../components/community-card';
import { environment } from '../../environment';
import RegisterLayout, { TRegisterLayoutProps } from '../../layouts/register';

function CommunityChoice({
  createCommunity,
  selectedGeogroup,
  selectGeogroup,
  ...props
}: TRegisterLayoutProps & {
  createCommunity: () => void;
  selectedGeogroup: Geogroup | null;
  selectGeogroup: (geogroup: Geogroup) => void;
}) {
  const {
    event: { config },
    user: { current: currentUser, geogroups, registeredGroups },
  } = useContext(AppContext);
  const theme = useTheme();

  return (
    <RegisterLayout
      {...props}
      disableNext={!selectedGeogroup}
      prevButtonLabel="Changer de compte"
      title="Utiliser une communauté existante"
    >
      {geogroups && registeredGroups ? (
        <>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="body2">
              Vous avez déjà des communautés liées à votre compte. Vous pouvez en choisir une afin
              de la relier directement {config === 'mdv' ? 'au Mois du Vélo' : 'à Mai à Vélo'} !
            </Typography>
          </Box>
          {geogroups.length > 0 && (
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography fontWeight={600}>Sélectionner la communauté à utiliser</Typography>
              {geogroups.map((geogroup) => {
                const registered = !!registeredGroups.find((id) => id === geogroup.group.id);
                return (
                  <CommunityCard
                    geogroup={geogroup}
                    key={geogroup.group.id}
                    onSelect={registered ? undefined : selectGeogroup}
                    registered={registered}
                    selected={geogroup.group.id === selectedGeogroup?.id}
                  />
                );
              })}
            </Box>
          )}
          <Button
            color="primary"
            onClick={createCommunity}
            size="large"
            sx={{ textTransform: 'initial' }}
            variant="outlined"
          >
            Je souhaite créer une nouvelle communauté
          </Button>
          {config === 'mdv' && (
            <Button
              color="primary"
              component={Link}
              size="large"
              sx={{ textTransform: 'initial', color: `${theme.palette.primary.main} !important` }}
              to={`${environment.frontendUrl}/fr/communities/invites/CZFPBIL/${
                currentUser
                  ? `?auth-token=${HttpService.authorizationToken?.substring(
                      HttpService.authorizationToken.indexOf(' ') + 1,
                    )}&user-id=${currentUser.id}`
                  : ''
              }`}
              variant="outlined"
            >
              Je souhaite participer en tant que particulier
            </Button>
          )}
        </>
      ) : (
        <Fragment />
      )}
    </RegisterLayout>
  );
}

export default CommunityChoice;
