"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _api = require("./api");
Object.keys(_api).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _api[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _api[key];
    }
  });
});
var _association = require("./association");
Object.keys(_association).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _association[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _association[key];
    }
  });
});
var _badges = require("./badges");
Object.keys(_badges).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _badges[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _badges[key];
    }
  });
});
var _calendar = require("./calendar");
Object.keys(_calendar).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _calendar[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _calendar[key];
    }
  });
});
var _calories = require("./calories");
Object.keys(_calories).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _calories[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _calories[key];
    }
  });
});
var _company = require("./company");
Object.keys(_company).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _company[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _company[key];
    }
  });
});
var _contact = require("./contact");
Object.keys(_contact).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _contact[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _contact[key];
    }
  });
});
var _cup = require("./cup");
Object.keys(_cup).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _cup[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _cup[key];
    }
  });
});
var _directions = require("./directions");
Object.keys(_directions).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _directions[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _directions[key];
    }
  });
});
var _distance = require("./distance");
Object.keys(_distance).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _distance[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _distance[key];
    }
  });
});
var _duration = require("./duration");
Object.keys(_duration).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _duration[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _duration[key];
    }
  });
});
var _easy = require("./easy");
Object.keys(_easy).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _easy[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _easy[key];
    }
  });
});
var _edit = require("./edit");
Object.keys(_edit).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _edit[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _edit[key];
    }
  });
});
var _export = require("./export");
Object.keys(_export).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _export[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _export[key];
    }
  });
});
var _favorite = require("./favorite");
Object.keys(_favorite).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _favorite[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _favorite[key];
    }
  });
});
var _facilities = require("./facilities");
Object.keys(_facilities).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _facilities[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _facilities[key];
    }
  });
});
var _filter = require("./filter");
Object.keys(_filter).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _filter[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _filter[key];
    }
  });
});
var _flag = require("./flag");
Object.keys(_flag).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _flag[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _flag[key];
    }
  });
});
var _geovelo = require("./geovelo");
Object.keys(_geovelo).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _geovelo[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _geovelo[key];
    }
  });
});
var _geoveloInline = require("./geovelo-inline");
Object.keys(_geoveloInline).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _geoveloInline[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _geoveloInline[key];
    }
  });
});
var _geoveloInlineWhite = require("./geovelo-inline-white");
Object.keys(_geoveloInlineWhite).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _geoveloInlineWhite[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _geoveloInlineWhite[key];
    }
  });
});
var _geoveloText = require("./geovelo-text");
Object.keys(_geoveloText).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _geoveloText[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _geoveloText[key];
    }
  });
});
var _geoveloTextWhite = require("./geovelo-text-white");
Object.keys(_geoveloTextWhite).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _geoveloTextWhite[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _geoveloTextWhite[key];
    }
  });
});
var _geoveloVertical = require("./geovelo-vertical");
Object.keys(_geoveloVertical).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _geoveloVertical[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _geoveloVertical[key];
    }
  });
});
var _geoveloWhite = require("./geovelo-white");
Object.keys(_geoveloWhite).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _geoveloWhite[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _geoveloWhite[key];
    }
  });
});
var _hard = require("./hard");
Object.keys(_hard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _hard[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _hard[key];
    }
  });
});
var _home = require("./home");
Object.keys(_home).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _home[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _home[key];
    }
  });
});
var _homeOutlined = require("./home-outlined");
Object.keys(_homeOutlined).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _homeOutlined[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _homeOutlined[key];
    }
  });
});
var _languages = require("./languages");
Object.keys(_languages).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _languages[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _languages[key];
    }
  });
});
var _like = require("./like");
Object.keys(_like).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _like[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _like[key];
    }
  });
});
var _likeOutlined = require("./like-outlined");
Object.keys(_likeOutlined).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _likeOutlined[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _likeOutlined[key];
    }
  });
});
var _legend = require("./legend");
Object.keys(_legend).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _legend[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _legend[key];
    }
  });
});
var _link = require("./link");
Object.keys(_link).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _link[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _link[key];
    }
  });
});
var _lock = require("./lock");
Object.keys(_lock).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _lock[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _lock[key];
    }
  });
});
var _lockOutlined = require("./lock-outlined");
Object.keys(_lockOutlined).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _lockOutlined[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _lockOutlined[key];
    }
  });
});
var _medium = require("./medium");
Object.keys(_medium).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _medium[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _medium[key];
    }
  });
});
var _parking = require("./parking");
Object.keys(_parking).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _parking[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _parking[key];
    }
  });
});
var _participants = require("./participants");
Object.keys(_participants).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _participants[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _participants[key];
    }
  });
});
var _people = require("./people");
Object.keys(_people).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _people[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _people[key];
    }
  });
});
var _person = require("./person");
Object.keys(_person).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _person[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _person[key];
    }
  });
});
var _place = require("./place");
Object.keys(_place).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _place[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _place[key];
    }
  });
});
var _poi = require("./poi");
Object.keys(_poi).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _poi[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _poi[key];
    }
  });
});
var _printable = require("./printable");
Object.keys(_printable).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _printable[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _printable[key];
    }
  });
});
var _profiles = require("./profiles");
Object.keys(_profiles).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _profiles[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _profiles[key];
    }
  });
});
var _ranks = require("./ranks");
Object.keys(_ranks).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ranks[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ranks[key];
    }
  });
});
var _remove = require("./remove");
Object.keys(_remove).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _remove[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _remove[key];
    }
  });
});
var _report = require("./report");
Object.keys(_report).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _report[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _report[key];
    }
  });
});
var _ride = require("./ride");
Object.keys(_ride).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ride[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ride[key];
    }
  });
});
var _rocket = require("./rocket");
Object.keys(_rocket).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _rocket[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _rocket[key];
    }
  });
});
var _routing = require("./routing");
Object.keys(_routing).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _routing[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _routing[key];
    }
  });
});
var _savedCo = require("./saved-co2");
Object.keys(_savedCo).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _savedCo[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _savedCo[key];
    }
  });
});
var _school = require("./school");
Object.keys(_school).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _school[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _school[key];
    }
  });
});
var _send = require("./send");
Object.keys(_send).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _send[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _send[key];
    }
  });
});
var _sncf = require("./sncf");
Object.keys(_sncf).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _sncf[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _sncf[key];
    }
  });
});
var _social = require("./social");
Object.keys(_social).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _social[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _social[key];
    }
  });
});
var _speed = require("./speed");
Object.keys(_speed).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _speed[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _speed[key];
    }
  });
});
var _swap = require("./swap");
Object.keys(_swap).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _swap[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _swap[key];
    }
  });
});
var _transportModes = require("./transport-modes");
Object.keys(_transportModes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _transportModes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _transportModes[key];
    }
  });
});
var _verticalGain = require("./vertical-gain");
Object.keys(_verticalGain).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _verticalGain[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _verticalGain[key];
    }
  });
});
var _website = require("./website");
Object.keys(_website).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _website[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _website[key];
    }
  });
});
var _work = require("./work");
Object.keys(_work).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _work[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _work[key];
    }
  });
});
var _workOutlined = require("./work-outlined");
Object.keys(_workOutlined).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _workOutlined[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _workOutlined[key];
    }
  });
});