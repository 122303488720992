import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../app/context';
import Helmet from '../components/helmet';
import PageLayout from '../layouts/page';

function GCU(): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);
  const { origin } = window.location;

  return (
    <>
      <Helmet
        description={`Conditions générales d'utilisation du site de challenges ${
          config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'
        } par Geovelo`}
        title={`CGU | Challenges ${config === 'mdv' ? 'Mois du vélo' : 'Mai à vélo'} par Geovelo`}
      />
      <PageLayout title="Conditions générales d'utilisation">
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          marginX="auto"
          maxWidth="100%"
          width="80ch"
        >
          <section>
            <Typography component={Box}>
              <p>
                <strong>
                  Date d’entrée en vigueur : {config === 'mdv' ? '01/04/2024' : '24/03/2022'}
                </strong>
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              1. Objet
            </Typography>
            <Typography component={Box}>
              <p>
                {config === 'mdv'
                  ? 'L’association Vélo Québec souhaite promouvoir la pratique du vélo, grâce à une animation collective organisée sous forme de défi.'
                  : 'Mai à Vélo est une initiative créée par un collectif d’organismes nationaux du monde du vélo dont l’objectif est de promouvoir la pratique du vélo à travers des événements cyclables et pédagogiques.'}
                <br />
                Dans ce cadre, le {config === 'mdv' ? 'Mois du vélo' : 'Challenge Mai à Vélo'} (le «{' '}
                <strong>Challenge</strong> ») a été mis en place. Le Challenge a pour objectif de
                faire réaliser à ses participants le plus de kilomètres à vélo. Le Challenge
                s’adresse à toute organisation privée ou publique (les «
                <strong>Utilisateurs</strong> ») qui souhaite proposer à ses membres (la «{' '}
                <strong>Communauté</strong> ») de relever le Challenge.
                <br />
                Dans ce cadre, la société La Compagnie des Mobilités (« <strong>Geovelo</strong> »)
                met à disposition des Utilisateurs le site internet{' '}
                <a href={origin} rel="noreferrer" target="_blank">
                  {origin}
                </a>{' '}
                (la « <strong>Plateforme</strong> ») leur permettant (i) d’inscrire leur Communauté
                au Challenge, (ii) de consulter les classements du Challenge et (iii) les cas
                échéant, d’effectuer une déclaration manuelle des trajets réalisés par leur
                Communauté (ensemble les « <strong>Services</strong> »).
                <br />
                Les classements disponibles sur la Plateforme sont accessibles à tous visiteurs de
                la Plateforme, que cette dernière ait ou non la qualité d’Utilisateur (le “
                <strong>Visiteur</strong>”).
                <br />
                Les présentes conditions générales d’utilisation (les «{' '}
                <strong>Conditions Générales</strong> ») ont pour objet de définir les modalités et
                conditions d’utilisation des Services ainsi que de définir les droits et obligations
                des parties dans ce cadre.
                <br />
                Les présentes Conditions Générales prévalent sur toutes autres conditions générales
                ou particulières non expressément agréées par Geovelo.
                <br />
                Elles peuvent être complétées le cas échéant par des conditions d’utilisation
                particulières à certains Services, lesquelles complètent les présentes Conditions
                Générales et, en cas de contradiction, prévalent sur ces dernières.
                <br />
                Elles sont accessibles et imprimables à tout moment par un lien direct en bas de
                page.
                <br />
                Il est à préciser que Geovelo propose un autre service gratuit qui ne repose pas sur
                la vente de données à caractère personnel mais sur la valorisation de données
                anonymes relatives à la circulation au sein de l’espace urbain afin d’en faire
                bénéficier les collectivités et leur permettre l’aménagement des villes à la
                pratique du vélo. Pour plus d’informations, consulter{' '}
                <a href="https://geovelo.app/fr/eula" rel="noreferrer" target="_blank">
                  geovelo.app/fr/eula
                </a>
                .
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              2. Exploitant de la Plateforme et des Services, contact
            </Typography>
            <Typography component={Box}>
              <p>
                La Plateforme et les Services sont proposés par la société La Compagnie des
                Mobilités, exploités commercialement sous le nom Geovelo, SAS, immatriculée au RCS
                de Tours sous le n° 521 563 742, dont le siège social est situé 1 Impasse du Palais,
                37 000 Tours (Geovelo).
                <br />
                Geovelo peut être contactée aux coordonnées suivantes, notamment pour toute
                réclamation :<br />
                Adresse postale : 1 Impasse du Palais, 37 000 Tours.
                <br /> Adresse électronique :{' '}
                <a href="mailto:contact@geovelo.fr">contact@geovelo.fr</a>
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              3. Accès aux Services par l’Utilisateur
            </Typography>
            <Typography component={Box}>
              <p>Les Services sont accessibles :</p>
              <ul>
                <li>
                  à toute personne physique disposant de la pleine capacité juridique pour s’engager
                  au titre des présentes Conditions Générales. La personne physique qui ne dispose
                  pas de la pleine capacité juridique ne peut accéder à la Plateforme et aux
                  Services qu’avec l’accord de son représentant légal ;
                </li>
                <li>
                  à toute personne morale agissant par l’intermédiaire d’une personne physique
                  disposant du pouvoir ou de l’habilitation requise pour contracter au nom et pour
                  le compte de la personne morale.
                </li>
              </ul>
              <p>
                L’Utilisateur a la qualité de professionnel, entendu comme toute personne physique
                ou morale agissant à des fins entrant dans le cadre de son activité commerciale,
                industrielle, artisanale, libérale ou agricole, y compris lorsqu'elle agit au nom ou
                pour le compte d'un autre professionnel.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              4. Acceptation des Conditions Générales
            </Typography>
            <Typography component={Box}>
              <p>
                <strong>4.1</strong> L’acceptation des présentes Conditions Générales par les
                Utilisateurs est matérialisée par une case à cocher dans le formulaire.
                <br /> <br />
                <strong>4.2</strong> L’acceptation des présentes Conditions Générales ne peut être
                que pleine et entière. Toute adhésion sous réserve est considérée comme nulle et non
                avenue. L’Utilisateur qui n’accepte pas d’être lié par les présentes Conditions
                Générales ne doit pas utiliser les Services.
                <br /> <br />
                <strong>4.3</strong> La consultation de la Plateforme, et plus particulièrement des
                classements par un Visiteur, entraîne son acceptation des Conditions Générales, sans
                restriction ni réserve.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              5. Description des Services
            </Typography>
            <Typography component={Box}>
              <p>
                <strong>5.1 Inscription de la Communauté au Challenge</strong>
                <br />
                <br /> L’Utilisateur remplit un formulaire pour pouvoir inscrire sa Communauté au
                Challenge. L’Utilisateur doit fournir l’ensemble des informations marquées comme
                obligatoires. Tout formulaire incomplet ne sera pas validé.
                <br />
                L’Utilisateur garantit que toutes les informations qu’il donne dans le formulaire
                sont exactes, à jour et sincères et ne sont entachées d’aucun caractère trompeur.
                <br />
                Geovelo transmet à l’Utilisateur un email lui confirmant la création de la
                Communauté. <br />
                Les membres de la Communauté pourront s’inscrire sur l’application Geovelo («{' '}
                <strong>l’Application »</strong>). L’Application a notamment pour objet de
                comptabiliser la distance parcourue par chaque membre de la Communauté dans le cadre
                du Challenge et de transmettre ces informations à la Plateforme, de façon à
                permettre la réalisation des classements.
                <br />
                <br />
                <strong>5.2 Consultation des classements au Challenge</strong>
                <br />
                <br />
                La Plateforme donne accès au :
              </p>
              <ul>
                <li>Classement des collectivités</li>
                <li>Classement des employeurs</li>
                <li>Classement des associations</li>
                <li>Classements des établissements scolaires</li>
                <li>Classement général par zone géographique</li>
              </ul>
              <p>
                La Plateforme est consultable par tout Visiteur.
                <br />
                <br />
                <strong>5.3 Déclaration manuelle des trajets réalisés par la Communauté</strong>
                <br />
                <br />
                Si tout ou partie de la Communauté n’est pas ou ne peut pas s’inscrire sur
                l’Application, l’Utilisateur peut remplir de façon manuelle les trajets réalisés par
                chaque membre de la Communauté.
                <br />
                Pour ce faire, l’Utilisateur devra en faire la demande auprès de Geovelo, afin que
                cette dernière lui transmette un lien permettant de procéder à cet enregistrement
                manuel. L’enregistrement manuel implique pour l’Utilisateur de disposer d’un compte
                sur l’Application. Il faut donc distinguer 2 hypothèses :
              </p>
              <ul>
                <li>
                  l’Utilisateur dispose déjà d’un compte sur l’Application : il se connecte sur son
                  compte une fois le lien transmis par Geovelo et peut procéder aux déclarations
                  manuelles,
                </li>
                <li>
                  l’Utilisateur ne dispose pas d’un compte sur l’Application : Geovelo créé un
                  compte au nom de l’Utilisateur que ce dernier devra valider, en définissant ses
                  identifiants et acceptant les conditions générales d’utilisation de l’Application
                  accessibles ici :{' '}
                  <a href="https://geovelo.app/fr/eula/" rel="noreferrer" target="_blank">
                    geovelo.app/fr/eula
                  </a>
                  . Une fois l’inscription sur l’Application validée, l’Utilisateur pourra procéder
                  aux déclarations manuelles.
                </li>
              </ul>
              <p>
                Geovelo se réserve la possibilité de proposer tout autre Service qu’elle jugera
                utile, sous une forme et selon les fonctionnalités et moyens techniques qu’elle
                estimera les plus appropriés pour rendre lesdits Services.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              6. Services gratuits
            </Typography>
            <Typography component={Box}>
              <p>
                Les Services sont fournis à titre gratuit.
                <br />
                Comme indiqué au préambule, Geovelo ne vend pas de données à caractère personnel en
                contrepartie de la fourniture des Services.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              7. Durée des Services
            </Typography>
            <Typography component={Box}>
              <p>
                Les Services sont souscrits pour une durée indéterminée.
                <br />
                L’Utilisateur peut se désinscrire des Services à tout moment, en adressant une
                demande à cet effet à Geovelo par email, aux coordonnées mentionnées à l’article «
                Exploitant de la Plateforme et de Services, contact ».
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              8. Obligations de l’Utilisateur
            </Typography>
            <Typography component={Box}>
              <p>
                Sans préjudice des autres obligations prévues aux présentes, l’Utilisateur s’engage
                à respecter les obligations qui suivent.
                <br />
                <br />
                <strong>8.1</strong> L’Utilisateur s’engage, dans son usage des Services, à
                respecter les lois et règlements en vigueur et à ne pas porter atteinte aux droits
                de tiers ou à l’ordre public.
                <br />
                <br />
                <strong>8.2</strong> L’Utilisateur reconnaît avoir pris connaissance sur la
                Plateforme des caractéristiques et contraintes, notamment techniques, de l’ensemble
                des Services. Il est seul responsable de son utilisation des Services.
                <br />
                <br />
                <strong>8.3</strong> L’Utilisateur est seul responsable des contenus de toute nature
                (rédactionnels, graphiques, audios, audiovisuels ou autres, en ce compris la
                dénomination et/ou l’image éventuellement choisies par l’Utilisateur pour
                l’identifier sur la Plateforme) qu’il diffuse dans le cadre des Services (les «{' '}
                <strong>Contenus</strong> »).
                <br />
                Il garantit à Geovelo qu’il dispose de tous les droits et autorisations nécessaires
                à la diffusion de ces Contenus.
                <br />
                Il s’engage à ce que lesdits Contenus soient licites, ne portent pas atteinte à
                l’ordre public, aux bonnes mœurs ou aux droits de tiers, n’enfreignent aucune
                disposition législative ou règlementaire et plus généralement, ne soient aucunement
                susceptibles de mettre en jeu la responsabilité civile ou pénale de Geovelo.
                <br />
                <br />
                <strong>8.4</strong> L’Utilisateur est informé et accepte que la mise en œuvre des
                Services nécessite qu’il soit connecté à internet et que la qualité des Services
                dépend directement de cette connexion, dont il est seul responsable.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              9. Garantie de l’Utilisateur
            </Typography>
            <Typography component={Box}>
              <p>
                L’Utilisateur garantit Geovelo contre toutes plaintes, réclamations, actions et/ou
                revendications quelconques que Geovelo pourrait subir du fait de la violation, par
                l’Utilisateur de l’une quelconque de ses obligations ou garanties aux termes des
                présentes Conditions Générales.
                <br />
                Il s’engage à indemniser Geovelo de tout préjudice qu’elle subirait et à lui payer
                tous les frais, charges et/ou condamnations qu’elle pourrait avoir à supporter de ce
                fait.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              10. Comportements prohibés
            </Typography>
            <Typography component={Box}>
              <p>
                <strong>10.1</strong> Il est strictement interdit d’utiliser les Services aux fins
                suivantes :
              </p>
              <ul>
                <li>
                  l’exercice d’activités illégales, frauduleuses ou portant atteinte aux droits ou à
                  la sécurité des tiers,
                </li>
                <li>
                  l’atteinte à l’ordre public ou la violation des lois et règlements en vigueur,
                </li>
                <li>
                  l’intrusion dans le système informatique d’un tiers ou toute activité de nature à
                  nuire, contrôler, interférer, ou intercepter tout ou partie du système
                  informatique d’un tiers, en violer l’intégrité ou la sécurité,
                </li>
                <li>
                  l’envoi d’emails non sollicités et/ou de prospection ou sollicitation commerciale,
                </li>
                <li>
                  l’aide ou l’incitation, sous quelque forme et de quelque manière que ce soit, à un
                  ou plusieurs des actes et activités décrits ci-dessus,
                </li>
                <li>
                  et plus généralement toute pratique détournant les Services à des fins autres que
                  celles pour lesquelles ils ont été conçus.
                </li>
              </ul>
              <p>
                <strong>10.2</strong> Il est strictement interdit aux Utilisateurs de copier et/ou
                de détourner à leurs fins ou à celles de tiers le concept, les technologies, tout ou
                partie des données ou tout autre élément de la Plateforme de Geovelo.
                <br />
                <br />
                <strong>10.3</strong> Sont également strictement interdits : (i) tous comportements
                de nature à interrompre, suspendre, ralentir ou empêcher la continuité des Services,
                (ii) toutes intrusions ou tentatives d’intrusions dans les systèmes de Geovelo,
                (iii) tous détournements des ressources système de la Plateforme, (iv) toutes
                actions de nature à imposer une charge disproportionnée sur les infrastructures de
                ce dernier, (v) toutes atteintes aux mesures de sécurité et d’authentification, (vi)
                tous actes de nature à porter atteinte aux droits et intérêts financiers,
                commerciaux ou moraux de Geovelo ou des usagers de son Plateforme, et enfin plus
                généralement (vii) tout manquement aux présentes Conditions Générales.
                <br />
                <br />
                <strong>10.4</strong> Il est strictement interdit de monnayer, vendre ou concéder
                tout ou partie de l’accès aux Services ou à la Plateforme, ainsi qu’aux informations
                qui y sont hébergées et/ou partagées.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              11. Sanctions des manquements
            </Typography>
            <Typography component={Box}>
              <p>
                En cas de manquement à l’une quelconque des dispositions des présentes Conditions
                Générales ou plus généralement, d’infraction aux lois et règlements en vigueur par
                un Utilisateur, Geovelo se réserve le droit de prendre toute mesure appropriée et
                notamment de :
              </p>
              <ul>
                <li>
                  suspendre ou résoudre l’accès aux Services de l’Utilisateur, auteur du manquement
                  ou de l’infraction, ou y ayant participé,
                </li>
                <li>
                  supprimer tout Contenu mis en ligne sur la Plateforme en lien avec le manquement
                  ou l’infraction considéré(e), en totalité ou en partie,
                </li>
                <li>
                  publier sur la Plateforme tout message d’information que Geovelo jugera utile,
                </li>
                <li>
                  avertir toute autorité compétente, coopérer avec elles et leur fournir toutes les
                  informations utiles à la recherche et à la répression d’activités illégales ou
                  illicites,
                </li>
                <li>engager toute action judiciaire.</li>
              </ul>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              12. Responsabilité et garantie de Geovelo
            </Typography>
            <Typography component={Box}>
              <p>
                <strong>12.1</strong> Geovelo s’engage à fournir les Services avec diligence et
                selon les règles de l’art, étant précisé qu’il pèse sur elle une obligation de
                moyens, à l’exclusion de toute obligation de résultat, ce que les Utilisateurs
                reconnaissent et acceptent expressément.
                <br />
                <br />
                <strong>12.2</strong> Geovelo n’a pas connaissance des Contenus mis en ligne par les
                Utilisateurs dans le cadre des Services, sur lesquels elle n’effectue aucune
                modération, sélection, vérification ou contrôle d’aucune sorte et à l’égard desquels
                elle n’intervient qu’en tant que prestataire d’hébergement.
                <br />
                En conséquence, Geovelo ne peut être tenue pour responsable des Contenus, dont les
                auteurs sont des tiers, toute réclamation éventuelle devant être dirigée en premier
                lieu vers l’auteur des Contenus en question.
                <br />
                Les Contenus préjudiciables à un tiers peuvent faire l’objet d’une notification à
                Geovelo selon les modalités prévues par l’article 6 I 5 de la loi n° 2004-575 du 21
                juin 2004 pour la confiance dans l’économie numérique, Geovelo se réservant de
                prendre les mesures décrites à l’article « Sanctions des manquements ».
                <br />
                <br />
                <strong>12.3</strong> Geovelo s’engage à procéder régulièrement à des contrôles afin
                de vérifier le fonctionnement et l’accessibilité de la Plateforme. À ce titre,
                Geovelo se réserve la faculté d’interrompre momentanément l’accès à la Plateforme
                pour des raisons de maintenance. De même, Geovelo ne saurait être tenue responsable
                des difficultés ou impossibilités momentanées d’accès à la Plateforme qui auraient
                pour origine des circonstances qui lui sont extérieures, la force majeure, ou encore
                qui seraient dues à des perturbations des réseaux de télécommunication.
                <br />
                <br />
                <strong>12.4</strong> Geovelo ne garantit pas aux Utilisateurs (i) que les Services,
                soumis à une recherche constante pour en améliorer notamment la performance et le
                progrès, seront totalement exempts d’erreurs, de vices ou défauts, (ii) que les
                Services, étant standard et nullement proposés à la seule intention d’un Utilisateur
                donné en fonction de ses propres contraintes personnelles, répondront spécifiquement
                à ses besoins et attentes.
                <br />
                <br />
                <strong>12.5</strong> En tout état de cause, la responsabilité susceptible d’être
                encourue par Geovelo au titre des présentes est expressément limitée aux seuls
                dommages directs avérés subis par l’Utilisateur.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              13. Propriété intellectuelle
            </Typography>
            <Typography component={Box}>
              <p>
                <strong>13.1 Propriété intellectuelle de Geovelo</strong>
                <br />
                <br />
                Les systèmes, logiciels, structures, infrastructures, bases de données et contenus
                de toute nature (textes, images, visuels, musiques, logos, marques, base de données,
                etc.) exploités par Geovelo au sein de la Plateforme sont protégés par tous droits
                de propriété intellectuelle ou droits des producteurs de bases de données en
                vigueur. Tous désassemblages, décompilations, décryptages, extractions,
                réutilisations, copies et plus généralement, tous actes de reproduction,
                représentation, diffusion et utilisation de l’un quelconque de ces éléments, en tout
                ou partie, sans l’autorisation de Geovelo sont strictement interdits et pourront
                faire l’objet de poursuites judiciaires.
                <br />
                <br />
                <strong>13.2 Propriété intellectuelle de l’Utilisateur</strong>
                <br />
                <br />
                L’Utilisateur reconnaît et accepte ainsi que par la mise en ligne de Contenus sur la
                Plateforme, il a l’intention de participer volontairement et librement à la
                diversité de ces informations, dans l’esprit d’un échange communautaire. À ce titre
                et aux fins de mise en œuvre des Services :
              </p>
              <ul>
                <li>
                  Il consent à ce que ses Contenus soient diffusés à titre gracieux par Geovelo sur
                  la Plateforme et sur tous autres sites internet français ou étrangers, édités par
                  toutes sociétés avec lesquelles Geovelo a des accords,
                </li>
                <li>
                  Il consent à ce que ses Contenus soient diffusés par Geovelo par tout moyen et sur
                  tout support aux fins de promotion de la Plateforme,
                </li>
                <li>
                  Il accepte que ses Contenus rédactionnels soient traduits en toutes langues,
                </li>
                <li>
                  Il reconnaît et accepte que les Contenus pourront faire l’objet de modifications,
                  notamment quant à leur cadrage, leur format et leurs couleurs, ainsi que
                  d’altérations ou de dégradations dans leur qualité, en fonction des contraintes
                  techniques de la Plateforme.
                </li>
                <li>
                  Il renonce à demander à Geovelo une quelconque rémunération, redevance, indemnité
                  ou compensation financière à ce titre.
                </li>
              </ul>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              14. Autorisation de diffusion de l’image et des témoignages
            </Typography>
            <Typography component={Box}>
              <ul>
                <li>
                  Pendant la durée de leur utilisation des Services, les Utilisateurs autorisent
                  Geovelo à utiliser les témoignages qu’ils publient sur la Plateforme (les «{' '}
                  <strong>Témoignages</strong> ») pour la promotion de celle-ci, selon les modalités
                  suivantes :
                </li>
                <li>
                  Ils consentent à ce que leurs Témoignages soient diffusés à titre gracieux par
                  Geovelo sur la Plateforme et sur tous autres sites internet français ou étrangers,
                  édités par toutes sociétés avec lesquelles la Société a des accords,
                </li>
                <li>
                  Ils consentent à ce que leurs Témoignages soient diffusés par Geovelo par tout
                  moyen et sur tout support aux fins de promotion de la Plateforme,
                </li>
                <li>Ils acceptent que leurs Témoignages soient traduits en toutes langues,</li>
                <li>
                  Ils reconnaissent et acceptent que les Témoignages pourront faire l’objet de
                  modifications, notamment quant à leur cadrage, leur format et leurs couleurs,
                  ainsi que d’altérations ou de dégradations dans leur qualité, en fonction des
                  contraintes techniques de la Plateforme,
                </li>
                <li>
                  Ils renoncent à demander à Geovelo une quelconque rémunération, redevance,
                  indemnité ou compensation financière à ce titre.{' '}
                </li>
              </ul>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              15. Données à caractère personnel
            </Typography>
            <Typography component={Box}>
              <p>
                Geovelo pratique une politique de protection des données personnelles dont les
                caractéristiques sont explicitées dans le document intitulé «{' '}
                <Link to="/politique-de-confidentialite">Politique de confidentialité</Link> », dont
                l’Utilisateur est expressément invité à prendre connaissance.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              16. Force majeure
            </Typography>
            <Typography component={Box}>
              <p>
                En aucun cas, les parties ne pourront être tenues pour responsables des manquements
                ou retards dans l’exécution des présentes Conditions Générales dus à l’un des cas de
                force majeure telle que défini par l’article 1218 du Code civil et reconnus par la
                jurisprudence, en ce compris notamment les grèves, activités terroristes, émeutes,
                insurrections, guerres, actions gouvernementales, épidémies, catastrophes naturelles
                ou défaut imputable à un prestataire tiers de télécommunication.
                <br />
                La survenance d’un cas de force majeure suspend l’exécution des Conditions Générales
                dès réception par l’une des parties de l’information transmise par lettre
                recommandée avec demande d’avis de réception par l’autre partie.
                <br />
                Si un cas de force majeure faisant obstacle à l’exécution de ses obligations par une
                partie se poursuit au-delà d’une durée de 30 (trente) jours, les Conditions
                Générales pourront être résiliées le cas échéant immédiatement et de plein droit,
                sans formalités judiciaires, par l’une ou l’autre des parties, par tout moyen écrit,
                sans qu’aucune des parties n’ait à verser à l’autre une quelconque indemnité.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              17. Modifications
            </Typography>
            <Typography component={Box}>
              <p>
                Geovelo se réserve la faculté de modifier à tout moment les présentes Conditions
                Générales.
                <br />
                L’Utilisateur inscrit sera informé de ces modifications par tout moyen utile trente
                (30) jours au moins avant leur entrée en vigueur.
                <br />
                Les Conditions Générales modifiées sont applicables à l’Utilisateur dès leur entrée
                en vigueur.
                <br />
                L’Utilisateur qui n’accepte pas les conditions générales modifiées doit se
                désinscrire des Services selon les modalités prévues à l’article « Durée des
                Services ».
                <br />
                Concernant le Visiteur n’ayant pas la qualité d'Utilisateur, il est invité à
                consulter régulièrement sur la Plateforme la dernière version en vigueur des
                Conditions Générales.
              </p>
            </Typography>
          </section>
          <section>
            <Typography component={Box}>
              <p>
                <a
                  href="https://drive.google.com/file/d/13UKFbcpcckO9rpHi_MqpzbpwjRYTjrRC/view"
                  rel="noreferrer"
                  target="_blank"
                >
                  Télécharger les Conditions Générales d’Utilisation
                </a>
              </p>
            </Typography>
          </section>
        </Box>
      </PageLayout>
    </>
  );
}

export default GCU;
