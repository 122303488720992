import {
  GeoveloVerticalIcon,
  TEventRankingItem,
  TGeogroupType,
  useFileSaver,
  useUnits,
} from '@geovelo-frontends/commons';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import html2canvas from 'html2canvas';
import { useContext, useRef, useState } from 'react';

import { AppContext } from '../app/context';
import communityIcon from '../assets/img/community.svg';
import background from '../assets/img/leaderboard/background.svg';
import { TEntity, entitiesMap } from '../models/entities';

import { MAVIcon, MDVIcon } from './icons';

const now = new Date();

function LeaderboardShareDialog<T extends string>({
  entity,
  selectedTypes,
  selectedZone,
  selectedSize: _selectedSize,
  items,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  entity: TEntity<T>;
  items: TEventRankingItem[];
  onClose: () => void;
  selectedSize: T | null | undefined;
  selectedTypes: TGeogroupType[] | undefined;
  selectedZone: { id: number; name: string } | undefined;
}): JSX.Element {
  const {
    event: { config },
  } = useContext(AppContext);
  const [downloading, setDownloading] = useState(false);
  const { toDistance } = useUnits();
  const { downloadBlob } = useFileSaver();
  const imageRef = useRef<HTMLDivElement | null>(null);

  async function handleDownload() {
    setDownloading(true);

    if (imageRef.current) {
      const canvas = await html2canvas(imageRef.current, {
        scale: 3,
        height: 600,
        width: 600,
        useCORS: true,
      });

      const blob = await new Promise<Blob>((resolve, reject) => {
        try {
          canvas.toBlob(
            (blob) => {
              if (!blob) throw new Error('no blob');
              else resolve(blob);
            },
            'image/png',
            1.0,
          );
        } catch (err) {
          reject(err);
        }
      });

      downloadBlob(
        `classement-${now.toISOString().substring(0, 10)}-${(entity.key === 'area'
          ? selectedZone?.name || ''
          : entity.title
        )
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .replace(/\s+/g, '_')}${
          selectedSize
            ? `-${selectedSize.labelLong
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .replace(/\s+/g, '_')}`
            : ''
        }.png`,
        blob,
      );
    }

    setDownloading(false);
  }

  const selectedSize = entity.sizes.find(({ key }) => key === _selectedSize);
  const podiumItems = [items[1], items[0], items[2]].filter(Boolean);

  return (
    <Dialog fullWidth maxWidth="md" {...props}>
      <DialogTitle>Partager</DialogTitle>
      <DialogContent dividers>
        <Box
          alignItems="stretch"
          display="flex"
          flexDirection="column"
          gap={1}
          height={584}
          marginX="auto"
          paddingX={2}
          paddingY={1}
          ref={imageRef}
          sx={{
            backgroundColor: '#f3f6ff',
            backgroundImage: `url(${background})`,
            backgroundPosition: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }}
          width={568}
        >
          <Box display="flex" flexDirection="column" flexShrink={0}>
            <Typography
              color="primary"
              fontWeight={600}
              textTransform="uppercase"
              variant="caption"
            >
              Podium du{' '}
              {new Intl.DateTimeFormat('fr-FR', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
              }).format(now)}
            </Typography>
            <Typography color="primary" fontWeight={600} variant="caption">
              {entity.key === 'area' ? (
                <>
                  {selectedZone?.name}
                  {selectedTypes && selectedTypes.length > 0 && (
                    <>&nbsp;({selectedTypes.map((key) => entitiesMap[key]?.title).join(', ')})</>
                  )}
                </>
              ) : (
                entity.title
              )}
            </Typography>
            {selectedSize ? (
              <Typography color="primary" fontWeight={600} variant="caption">
                {selectedSize.labelLong}
              </Typography>
            ) : (
              <Typography color="primary" fontWeight={600} variant="caption">
                &nbsp;
              </Typography>
            )}
          </Box>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            flexShrink={0}
            gap={1}
            marginY={1}
          >
            <Typography color="primary" fontWeight={600} textTransform="uppercase" variant="h5">
              Challenge d'activité
            </Typography>
            <Box alignItems="center" display="flex" gap={5}>
              {config === 'mdv' ? (
                <MDVIcon sx={{ height: 90, width: 'auto' }} />
              ) : (
                <MAVIcon sx={{ height: 50, width: 'auto' }} />
              )}
              <GeoveloVerticalIcon sx={{ height: 60, width: 'auto' }} />
            </Box>
          </Box>
          <Box
            alignItems="flex-end"
            alignSelf="center"
            display="flex"
            flexGrow={1}
            gap={2}
            overflow="hidden"
            width={500}
          >
            {podiumItems.map(({ id, photo, parent_title, title, rank, progress_value }, index) => (
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                height={index === 0 ? 270 : index === 1 ? 290 : 250}
                justifyContent="flex-end"
                key={id}
                maxHeight="100%"
                width={`calc((100% - 32px) / 3)`}
              >
                <Typography
                  align="center"
                  color="primary"
                  fontWeight={600}
                  lineHeight={1.2}
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  variant="subtitle1"
                >
                  {parent_title ? `${parent_title} - ${title}` : title}
                </Typography>
                <Box
                  alignItems="center"
                  borderRadius={2}
                  display="flex"
                  flexDirection="column"
                  flexGrow={1}
                  gap={1}
                  justifyContent="space-between"
                  padding={2}
                  sx={{ backgroundColor: '#0A429A' }}
                >
                  <Typography color="#fff">{toDistance(progress_value)}</Typography>
                  <Box
                    borderRadius={2}
                    height={index === 1 ? 72 : 64}
                    padding={1}
                    sx={{ backgroundColor: '#FFF' }}
                    width={index === 1 ? 'calc(100% - 16px)' : 'calc(90% - 16px)'}
                  >
                    <Box
                      height="100%"
                      sx={{
                        backgroundImage: `url(${photo || communityIcon})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                      }}
                      width="100%"
                    />
                  </Box>
                  {index === 1 ? (
                    <Box
                      alignItems="center"
                      border="1px solid #fff"
                      borderRadius={35}
                      display="flex"
                      height={70}
                      justifyContent="center"
                      width={70}
                    >
                      <Typography color="#fff" fontSize="2.5rem" fontWeight={600}>
                        #1
                      </Typography>
                    </Box>
                  ) : (
                    <Typography color="#fff" fontSize="2rem" fontWeight={600} variant="h4">
                      #{rank}
                    </Typography>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
          <Box alignItems="center" display="flex" flexDirection="column" flexShrink={0}>
            {[3, 4, 5].map((index) => {
              const item = items[index];
              if (!item)
                return (
                  <Typography color="#fff" key={index} variant="body2">
                    &nbsp;
                  </Typography>
                );

              const { id, parent_title, title, rank, progress_value } = item;
              return (
                <Typography color="#fff" key={id} variant="body2">
                  #{rank} {parent_title ? `${parent_title} - ${title}` : title} (
                  {toDistance(progress_value)})
                </Typography>
              );
            })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.onClose()}
          sx={{
            borderRadius: 100,
            textTransform: 'initial',
          }}
        >
          Fermer
        </Button>
        <Button
          disableElevation
          color="primary"
          disabled={downloading}
          onClick={handleDownload}
          startIcon={downloading && <CircularProgress color="inherit" size={16} />}
          sx={{
            borderRadius: 100,
            textTransform: 'initial',
          }}
          variant="contained"
        >
          Télécharger l'image
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LeaderboardShareDialog;
